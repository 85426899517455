<template>
    <div class="related_platform">
        <div class="d-flex justify-content-between align-items-center">
            <h4>منصات تعليمية أخري قد تفيدك</h4>
            <router-link to="/platform">عرض الكل</router-link>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12" v-for="(card, index) in related_course" :key="index">
                <platformCard :card="card" />
            </div>
        </div>
    </div>
</template>

<script>
import platformCard from './platformCard.vue'
export default {
    props: ["related_course"],
    components: {
        platformCard
    }
}
</script>

<style>
    .card_platform {
        width: 80%;
        margin: 0 auto;
    }
</style>