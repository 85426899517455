<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="reservation_card">
    <div class="data">
      <div class="res_data">
        <div class="info">
          <p class="title">{{ $t("reservation_date") }}</p>
          <span class="text">{{ reservation.date }}</span>
        </div>
        <div class="info">
          <p class="title">{{ $t("reservation_type") }}</p>
          <span class="text">{{
            reservation.contact_type == 1
              ? $t("center_statement")
              : reservation.contact_type == 2
              ? $t("online_statement")
              : ""
          }}</span>
        </div>
        <div class="info">
          <p class="title">{{ $t("payment_type") }}</p>
          <span class="text">{{ reservation.payment_method_title }}</span>
        </div>
      </div>
      <div class="actions">
        <div
          :class="`status ${
            reservation.status == 0
              ? 'new'
              : reservation.status == 1
              ? 'wait'
              : reservation.status == 2
              ? 'done'
              : reservation.status == 3
              ? 'cancel'
              : ''
          }`"
        >
          {{
            reservation.status == 0
              ? $t("new_reservation")
              : reservation.status == 1
              ? $t("pendding_reservation")
              : reservation.status == 2
              ? $t("done_reservation")
              : reservation.status == 3
              ? $t("cancel")
              : ""
          }}
        </div>
        <button
          type="button"
          class="btn"
          v-if="reservation.status == 0"
          @click="cancelReservation(reservation.id)"
        >
          {{ $t("cancel_reservation") }}
        </button>
        <!-- <button class="btn" v-if="reservation.status == 2">{{$t("re_reservation")}}</button> -->
      </div>
    </div>
    <div class="doctor_data">
      <div class="reservation_data">
        <router-link :to="`/reservation/${reservation.id}`">
          <img
            :src="reservation.doctor_image"
            :alt="reservation.doctor_name"
            @error="setDefaultImage"
          />
        </router-link>
        <div class="data">
          <h6 class="title">{{ reservation.doctor_name }}</h6>
          <p class="text">{{ reservation.doctor_description }}</p>
          <div class="payment">
            <div class="payment_status">
              <span>{{ $t("payment_status") }}</span>
              <p>
                {{
                  reservation.payment_status == 0
                    ? $t("payment_has_not_been_confirmed_yet")
                    : reservation.payment_status == 1
                    ? $t("payment_data_reviewed")
                    : reservation.payment_status == 2
                    ? $t("pushed_inside_center")
                    : reservation.payment_status == 3
                    ? $t("visa_has_been_paid")
                    : reservation.payment_status == 4
                    ? $t("payment_has_been_confirmed")
                    : ""
                }}
              </p>
            </div>
            <p
              class="payment_modal"
              v-if="reservation.payment_status == 0"
              data-bs-toggle="modal"
              :data-bs-target="`#paymentModal${reservation.id}`"
            >
              {{ $t("click_confirm_payment") }}
            </p>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            :id="`paymentModal${reservation.id}`"
            tabindex="-1"
            aria-labelledby="paymentModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <form v-on:submit.prevent="SubmitForm" class="modal-body">
                  <h4>
                    {{ $t("Confirm_process_transferring_reservation_cost") }}
                  </h4>
                  <p>{{ $t("please_upload_transfer_image") }}</p>
                  <div class="form-group">
                    <label for="">{{ $t("transfer_image") }}</label>
                    <label for="transfer_image" class="transfer_image">
                      <i class="fa-solid fa-upload"></i>
                      <span>{{ $t("drag_image") }}</span>
                      <p v-if="selectedFile">{{ selectedFile.name }}</p>
                    </label>
                    <input
                      type="file"
                      name=""
                      id="transfer_image"
                      class="form-control"
                      placeholder=""
                      accept="image/png, image/jpeg, image/*"
                      @change="handleFileUpload"
                      hidden
                    />
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("price") }}</label>
                    <input
                      type="number"
                      name=""
                      id=""
                      class="form-control"
                      v-model="Form.receipt_code"
                      :placeholder="$t('write_amount_here')"
                    />
                  </div>
                  <button type="submit" class="btn PrimaryButton">
                    {{ $t("save") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        :href="reservation.receipt_image"
        v-if="reservation.receipt_image"
        target="_blank"
        class="receipt_image"
      >
        <img
          :src="reservation.receipt_image"
          :alt="reservation.another_person_name"
        />
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/include/LottieFiles/loading.vue"

export default {
  props: ["reservation"],
  data() {
    return {
      selectedFile: null,
      defaultImageUrl: localStorage.getItem("defaultImage"),
      Form: {
        reservation_id: this.reservation.id,
        receipt_code: "",
      },
      loading: false,
    };
  },
  components: {
    Loading
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
    cancelReservation(id) {
      let reservation_id = { reservation_id: id };
      axios
        .post("/cancel_reservation", reservation_id)
        .then(({ data }) => {
          this.data = data;
          if (this.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.data.message,
              timer: 2000,
            });
            location.reload();
          } else if (this.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.data.message,
              timer: 2000,
            });
          }
          // console.log(this.platform);
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            timer: 2000,
          });
        });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
    },
    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin

      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("receipt_image", this.selectedFile);

      axios
        .post("/send_reservation_image", formData)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            location.reload();
            // console.log(this.payment_type)
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
};
</script>

<style></style>
