<template>
  <!-- <router-link :to="`${this.currentUrl = 'my_platform' ? `/course/${card.id}` : this.currentUrl = 'platforms' ? `/platform/${card.id}` : ''}`" class="card_platform"> -->
  <router-link :to="`${card.IsBuyed == false ? `/platform/${card.id}` : `/course/${card.id}`}`" class="card_platform">
    <img :src="card.image" alt="platform" @error="setDefaultImage" />
    <h4 class="title">{{ card.title }}</h4>
    <p class="text" v-html="card.features.substring(0, 120) + ' ...'"></p>
    <span class="lessons">{{ card.number_of_lessons }} {{ $t("lesson") }}</span>
    <!-- <span class="hours"> , {{ card.total_hours }} {{ $t("hours") }}</span> -->
    <!-- <p class="price">
      {{ card.price }} <small>{{ $t("currency") }}</small>
    </p> -->
  </router-link>
</template>

<script>
export default {
  props: ["card"],
   data() {
    return {
      defaultImageUrl: localStorage.getItem('defaultImage'),
      auth: false,
    };
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  //  mounted() {
  // this.auth = this.$store.getters['isAuthenticated'];
  // console.log(this.auth);
  //   this.currentUrl = this.$route.name;
  //   console.log(this.currentUrl);
  // }
};
</script>

<style></style>
