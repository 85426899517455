<template>
  <section class="home_podcast">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-12">
          <h4 class="head_section">{{ $t("podcast") }}</h4>
          <h1 class="title_section">{{ podcast.title }}</h1>
          <h5 class="text_section">{{ podcast.text }}</h5>
          <PrimaryButton :PrimaryButtonLink="PrimaryButtonLink" :PrimaryButtonText="PrimaryButtonText" />
        </div>
        <div class="col-lg-7 col-md-12 col-12">
            <div class="content_podcast">
                <img :src="podcast.image" class="main_image" alt="podcast" @error="setDefaultImage">
                <div class="latest_podcast">
                    <p>{{$t("latest_podcast_added")}}</p>
                    <CardLitePodcast :podcast="podcast" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { podcastData } from "@/api/index/podcast.js";
import CardLitePodcast from "@/components/include/podcast/card_lite.vue";
import PrimaryButton from "@/components/include/buttons/PrimaryButton.vue";

export default {
  data() {
    return {
      PrimaryButtonLink: "podcast",
      PrimaryButtonText: this.$t("show_all"),
      status: "",
      podcast: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    PrimaryButton,
    CardLitePodcast
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  mounted() {
    podcastData().then((response) => {
      this.status = response.data.status;
      this.podcast = response.data.data;
      // console.log(this.podcast);
      return response;
    });
  },
};
</script>
