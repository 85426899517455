<template>
  <section class="special_advice">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="advice.image" alt="doctor" @error="setDefaultImage" />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="get_special_advice">
            <h1 class="title">{{ advice.title }}</h1>
            <p class="text">{{ advice.text }}</p>
            <p class="help">
              {{$t("Take_first_step_help")}}
              <span class="phone">{{advice.phone}}</span>
            </p>
            <SecondButton
              :SecondButtonLink="SecondButtonLink"
              :SecondButtonText="SecondButtonText"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SecondButton from "../buttons/SecondButton.vue";
import { SpecialAdviceData } from "@/api/index/SpecialAdvice.js";

export default {
  data() {
    return {
      SecondButtonLink: "consult",
      SecondButtonText: this.$t("request_consult"),
      advice: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    SecondButton,
  },
  mounted() {
    SpecialAdviceData().then((response) => {
      this.status = response.data.status;
      this.advice = response.data.data;
    //   console.log(this.advice);
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.SecondButton {
  width: fit-content;
  background-color: #fff !important;
  &:hover {
    background-color: transparent !important;
    border: 1px solid #fff !important;
  }
}
</style>
