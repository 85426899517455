<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="addRateStoreModal"
    tabindex="-1"
    aria-labelledby="addRateStoreModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <form v-on:submit.prevent="SubmitForm">
            <h4>{{$t("product_evaluation")}}</h4>
            <p>{{$t("product_evaluation_text")}}</p>
            <rate :length="5" v-model="Form.rate" />
            <div class="form-group">
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                class="form-control"
                v-model="Form.comment"
                :placeholder="$t('add_comment')"
              ></textarea>
            </div>
            <button type="submit" class="btn PrimaryButton">{{$t("send")}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Form: {
        book_id: this.$route.params.id,
        // title: "",
        rate: "",
        comment: "",
      },
    };
  },
  methods: {
    SubmitForm() {
      axios
        .post("/rate_book", this.Form)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.Form = {
              comment: "",
              rate: "",
            };
            location.reload();
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
small {
  font-family: "regular";
  color: #555d5f;
}
.btn {
  display: block;
  margin-top: 1rem;
  margin-inline-start: auto;
  border-radius: 10px !important;
}
</style>
