<template>
    <router-link :to="`/lesson/${lesson.id}`" class="lesson_card" v-for="(lesson, index) in lessons" :key="index">
        <img :src="lesson.image" :alt="lesson.title" @error="setDefaultImage">
        <h5 class="title">{{lesson.title}}</h5>
        <i class="fa-solid fa-circle-play"></i>
    </router-link>
</template>

<script>

export default {
    props: ["lessons"],
    data() {
        return {
            defaultImageUrl: localStorage.getItem('defaultImage'),
        }
    },
    methods: {
        
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },
    },
}
</script>

<style>

</style>