<template>
  <router-link :to="`/${PrimaryButtonLink}`" class="btn PrimaryButton">{{PrimaryButtonText}}</router-link>
</template>

<script>
export default {
    props: ["PrimaryButtonLink", "PrimaryButtonText"]
}
</script>

<style>

</style>