<template>
  <div class="rates">
    <h4>{{$t("all_comments")}} ({{ store.average_rate }} {{$t("comment")}})</h4>
    <div class="rate" v-for="(rate, index) in rates" :key="index">
      <img :src="rate.user_image" :alt="rate.name" @error="setDefaultImage" />
      <div class="content">
        <p class="name">{{ rate.user_name }}</p>
        <div class="rate">
          <i
            class="fa-solid fa-star"
            v-for="(star, index) in parseInt(rate.rate)"
            :key="index"
          ></i>
          <i
            class="fa-regular fa-star"
            v-for="(star, index) in 5 - parseInt(rate.rate)"
            :key="index"
          ></i>
        </div>
        <p class="text">{{ rate.comment }}</p>
      </div>
      <div class="dates">
        <span class="date">{{ rate.date }}</span>
        <span class="buyer">{{
          rate.buyer == true ? $t('reliable_buyer') : ""
        }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
        <!-- <p class="show_more">{{$t("view_more_comments")}}</p> -->
        <button type="button" class="btn anthor_questions SecondButton" data-bs-toggle="modal" data-bs-target="#addRateStoreModal">{{$t("do_specific_question")}}</button>
    </div>
    <AddRateStoreModal />
  </div>
</template>

<script>
import AddRateStoreModal from '../Modals/AddRateStoreModal.vue';

export default {
  props: ["store", "rates"],
  components: {
    AddRateStoreModal
  },
  data() {
    return {
      defaultImageUrl: localStorage.getItem('defaultImage'),
    }
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  }
};
</script>

<style>
.show_more {
    font-family: "regular";
    color: #809BCE;
}
.anthor_questions {
    font-family: "semibold";
    color: #809BCE;
    border: 1px solid #809BCE;
    padding: .75rem 3rem;
}
.anthor_questions:hover {
    color: #809BCE;
    border: 1px solid #809BCE;
}
</style>
