<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="account">
    <div class="container">
        <UpdateProfile />
        <UpdatePassword />
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import UpdateProfile from '@/components/include/profile/UpdateProfile.vue';
import UpdatePassword from '@/components/include/profile/UpdatePassword.vue';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("account"),
        subTitle: "",
        link: "",
      },
    };
  },
  components: {
    Breadcrumb,
    UpdateProfile,
    UpdatePassword
  },
};
</script>
