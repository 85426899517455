<template>
  <section class="blogs">
    <div class="container">
      <div class="d-flex justify-content-between align-items-start">
        <div class="content">
          <h4 class="head_section">{{ $t("blogs") }}</h4>
          <!-- <h1 class="title_section" v-html="text.blog_text"></h1> -->
          <h5 class="text_section" v-html="text.blog_text"></h5>
        </div>
        <SecondButton
          :SecondButtonLink="SecondButtonLink"
          :SecondButtonText="SecondButtonText"
        />
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12" v-for="(blog, index) in blogs" :key="index"
        data-aos="flip-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false">
            <CardBlog :blog="blog" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardBlog from "@/components/include/blogs/CardBlog.vue";
import { blogsData } from "@/api/index/blogs.js";
import SecondButton from "@/components/include/buttons/SecondButton.vue";
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      SecondButtonLink: "blogs",
      SecondButtonText: this.$t("show_more"),
      status: "",
      blogs: [],
      text: {}
    };
  },
  components: {
    CardBlog,
    SecondButton,
  },
  mounted() {
    blogsData().then((response) => {
      this.status = response.data.status;
      this.blogs = response.data.data;
      return response;
    });

    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
};
</script>
