<template>
  <!-- Start Nav top -->
  <div class="nav-top">
    <div class="container">
      <ButtonLang />
      <div class="contact_details">
        <SocialIcon />
        <a :href="`tel:${setting.phone}`" class="contact_info phone">{{setting.phone}}</a>
        <a :href="`mailto:${setting.email}`" class="contact_info">{{setting.email}}</a>
      </div>
    </div>
  </div>
  <!-- End Nav top -->

  <!-- Start navbar -->
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
         @click="toggleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link to="/" class="logo">
        <img src="@/assets/media/logo.png" alt="logo" />
      </router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{ 'show': isNavbarOpen }">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link" @click="closeNavbar">{{ $t("home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link" @click="closeNavbar">{{
              $t("about_doctor")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/podcast" class="nav-link" @click="closeNavbar">{{
              $t("podcast")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/platform" class="nav-link" @click="closeNavbar">{{
              $t("educational_platform")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/center" class="nav-link" @click="closeNavbar">{{
              $t("center")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/stores/0" class="nav-link" @click="closeNavbar">{{
              $t("store")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/blogs" class="nav-link" @click="closeNavbar">{{
              $t("blogs")
            }}</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/contact" class="nav-link" @click="closeNavbar">{{
              $t("contact_us")
            }}</router-link>
          </li> -->
          <div v-if="auth">
            <div class="dropdown">
              <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-regular fa-user"></i>
              </button>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" to="/account"><i class="fa-regular fa-user"></i> {{$t("account")}}</router-link></li>
                <li><router-link class="dropdown-item" to="/reservations"><i class="fa-regular fa-clipboard"></i> {{$t("my_reservastion")}}</router-link></li>
                <li><router-link class="dropdown-item" to="/my_platform"><i class="fa-regular fa-circle-play"></i> {{$t("my_platform")}}</router-link></li>
                <li><router-link class="dropdown-item" to="/my_podcast"><i class="fa-solid fa-microphone-lines"></i> {{$t("podcast")}}</router-link></li>
                <li><router-link class="dropdown-item" to="/my_book"><i class="fa-regular fa-bookmark"></i> {{$t("my_books")}}</router-link></li>
                <hr>
                <li><a class="dropdown-item" href="#" @click="removeToken"><i class="fa-solid fa-right-from-bracket"></i> {{$t("logout")}}</a></li>
              </ul>
            </div>
          </div>
          <LoginButton v-else-if="!auth" />
        </ul>
      </div>
    </div>
  </nav>
  <!-- End navbar -->
</template>

<script>
import ButtonLang from "@/components/include/buttons/LangButton.vue";
import LoginButton from "@/components/include/buttons/LoginButton.vue";
import SocialIcon from "@/components/include/social/socialicon.vue";
import { settingData } from "@/api/index/fetchSetting.js";

export default {
  name: "navbar",
  data() {
    return {
      show: true,
      auth:false,
      langStorage: localStorage.getItem('lang'),
      setting: {},
      isNavbarOpen: false
    };
  },
  components: {
    ButtonLang,
    LoginButton,
    SocialIcon
  },
  mounted() {
    settingData().then((response) => {
      this.status = response.data.status;
      this.setting = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
  methods: {
    removeToken: function(){
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$router.go('/');
    },
     changeLocale(locale) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      location.reload();
      // console.log(locale);
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    closeNavbar() {
      this.isNavbarOpen = false;
    }
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
    // console.log("Auth : " + this.auth);

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },
};
</script>

<style scoped>
  hr {
    margin: .25rem 0;
  }
</style>