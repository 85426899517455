<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="reservation_details">
    <div class="container">
      <div class="details">
        <div class="list">
          <i class="fa-solid fa-video"></i>
          <div class="data">
            <p>{{ $t("consultation_type") }}</p>
            <h5>{{reservation.contact_type == 1 ? $t("center_statement") : reservation.contact_type == 2 ? $t("online_statement") : ""}} </h5>
          </div>
        </div>
        <div class="list">
          <i class="fa-solid fa-calendar-days"></i>
          <div class="data">
            <p>{{ $t("consultation_date") }}</p>
            <h5>{{reservation.date}}</h5>
          </div>
        </div>
        <div class="list">
          <i class="fa-solid fa-circle-info"></i>
          <div class="data">
            <p>{{ $t("consultation_status") }}</p>
            <h5>{{reservation.status == 0 ? $t("new_reservation"): reservation.status == 1 ? $t("pendding_reservation") : reservation.status == 2 ? $t("done_reservation") : ""}}</h5>
          </div>
        </div>
      </div>
      <h4 class="title">{{ $t("general_details") }}</h4>
      <div class="card_details">
        <h5 class="title_card">{{$t("Patient_complaint")}}</h5>
        <p class="text_card">{{reservation.patient_notes}}</p>
      </div>

      <div class="card_details">
        <h5 class="title_card">{{$t("test_result")}}</h5>
        <p class="text_card">{{reservation.doctor_notes}}</p>
      </div>

      <div class="card_details">
        <h5 class="title_card">{{$t("Pictures_uploaded_by_physician")}}</h5>
        <div class="images">
          <img v-for="(image, index) in reservation.doctor_images" :key="index" :src="image" :alt="reservation.consultation_title">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import { reservationDetailsData } from "@/api/profile/reservationDetails.js";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("reservations"),
        subTitle: this.$t("reservation_details"),
        link: "/reservations",
      },
      reservation: {},
    };
  },
  components: {
    Breadcrumb,
  },
  created() {
    let reservation_id = {reservation_id : this.$route.params.id}
    reservationDetailsData(reservation_id).then((response) => {
      this.status = response.data.status;
      this.reservation = response.data.data;
      // console.log(this.reservation)
      return response;
    });
  },
};
</script>

<style></style>
