<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="podcasts">
    <div class="container">
      <h4 class="title_section">{{$t("all_podcasts")}}</h4>
      <podcastCard v-for="(podcast, index) in podcasts" :key="index" :podcast="podcast" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import podcastCard from '@/components/include/podcast/podcast_card.vue';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("podcast"),
        subTitle: "",
        link: "",
      },
      podcasts: [],
    };
  },
  components: {
    Breadcrumb,
    podcastCard
  },
  methods: {
    fetchMyPodcast() {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.get("/your_podcasts").then(({ data }) => {
            this.status = data.status;
            this.podcasts = data.data;
            // console.log(this.payments)
        });
    },
  },
  created() {
    this.fetchMyPodcast()
  },
};
</script>