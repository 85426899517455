<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="details_platform">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <h1 class="title">{{ platform.title }}</h1>
          <div class="details">
            <div class="details_count">
              <i class="fa-regular fa-circle-play"></i>
              <span class="title_details">{{ $t("lesson_number") }} : </span>
              <span class="text_details">{{ platform.number_of_lessons }}</span>
            </div>
            <!-- <div class="details_count">
              <i class="fa-regular fa-clock"></i>
              <span class="title_details">{{ $t("total_hours") }} : </span>
              <span class="text_details"
                >{{ platform.total_hours }} {{ $t("hours") }}</span
              >
            </div> -->
            <div class="details_count">
              <i class="fa-solid fa-money-bills"></i>
              <span class="title_details">{{ $t("price_course") }} : </span>
              <span class="text_details"
                >{{ platform.price }} {{ $t("currency") }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12" v-if="platform.IsBuyed == false">
          <div class="card_cost_course">
            <p>{{ $t("price_course") }}</p>
            <div class="currency">
              <span class="price"
                >{{ platform.price }} {{ $t("currency") }}</span
              >
              <!-- <span class="duration"> / {{ platform.course_duration }}</span> -->
            </div>
            <button type="button" class="btn get_course_btn" @click="getCourse">
              {{ $t("get_course_now") }}
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <video-player
          :src="platform.video"
          :poster="platform.image"
          controls
          :loop="true"
          :volume="0.6"
          download="false"
        />
        <h2 class="list_learn_title">
          {{ $t("What will you learn at the end of this course?") }}
        </h2>
        <p class="text" v-html="platform.features"></p>
        <Lessons :lessons="lessons" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
// import { platformDetailsData } from "@/api/page/platform/details.js";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import Swal from "sweetalert2";
import Lessons from "../lessons/lessons.vue";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("educational_platform"),
        subTitle: "",
        link: "/platform",
      },
      platform: {},
      CourseForm: {
        course_id: this.$route.params.id,
        payment_method_id: 1,
      },
      lessons: []
    };
  },
  components: {
    Breadcrumb,
    VideoPlayer,
    Lessons,
  },
  methods: {
    platformDetailsData() {
      let course_id = { course_id: this.$route.params.id };
      axios
        .post("/fetch_educational_platform_details", course_id)
        .then(({ data }) => {
          this.status = data.status;
          this.platform = data.data.details;
          // console.log(this.platform);
        });
    },
    fetchLessonsData() {
      let course_id = { course_id: this.$route.params.id };
      axios.post("/fetch_course_lessons", course_id).then(({ data }) => {
        this.status = data.status;
        this.lessons = data.data;
        // console.log(this.lessons);
      });
    },
    getCourse() {
      axios
        .post("/buy_course", this.CourseForm)
        .then(({ data }) => {
          this.data = data;
          if (this.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.data.message,
              timer: 2000,
            });
          } else if (this.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.data.message,
              timer: 2000,
            });
          }
          // console.log(this.platform);
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            timer: 2000,
          });
        });
    },
  },
  created() {
    this.platformDetailsData();
    this.fetchLessonsData();
  },
  watch: {
    $route: {
      handler: "platformDetailsData",
      immediate: true,
    },
  },
};
</script>

<style></style>
