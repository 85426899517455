<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="details_platform">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <h1 class="title">{{ platform.title }}</h1>
          <div class="details">
            <div class="details_count">
              <i class="fa-regular fa-circle-play"></i>
              <span class="title_details">{{ $t("lesson_number") }} : </span>
              <span class="text_details">{{ platform.number_of_lessons }}</span>
            </div>
            <!-- <div class="details_count">
              <i class="fa-regular fa-clock"></i>
              <span class="title_details">{{ $t("total_hours") }} : </span>
              <span class="text_details"
                >{{ platform.total_hours }} {{ $t("hours") }}</span
              >
            </div> -->
            <div class="details_count">
              <i class="fa-solid fa-money-bills"></i>
              <span class="title_details">{{ $t("price_course") }} : </span>
              <span class="text_details"
                >{{ platform.price }} {{ $t("currency") }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12" v-if="platform.IsBuyed == false">
          <div class="card_cost_course">
            <p>{{ $t("price_course") }}</p>
            <div class="currency">
              <span class="price">{{ platform.price }} {{ $t("currency") }}</span>
              <!-- <span class="duration"> / {{ platform.course_duration }}</span> -->
            </div>
            <router-link :to="`/platform/payment/${platform.id}`" v-if="platform.IsBuyed == false && auth == true" class="btn get_course_btn">
              {{ $t("get_course_now") }}
            </router-link>
            <button type="submit" v-else-if="auth == false" class="btn get_course_btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("get_course_now")}}</button>
          </div>
        </div>
      </div>

      <div class="row">
        <video-player
          :src="platform.video"
          :poster="platform.image"
          controls
          :loop="true"
          :volume="0.6"
          download="false"
        />
      <h2 class="list_learn_title">{{$t("What will you learn at the end of this course?")}}</h2>
      <p class="text" v-html="platform.features"></p>
      <!-- <ul class="list_learn">
        <li v-for="(learn, index) in platform.list_learn" :key="index">{{learn}}</li>
      </ul> -->
      </div>
      <RelatedPlatform :related_course="related_course" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import RelatedPlatform from '@/components/include/platform/RelatedPlatform.vue';
// import { platformDetailsData } from "@/api/page/platform/details.js";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("educational_platform"),
        subTitle: "",
        link: "/platform",
      },
      platform: {},
      auth: false,
    };
  },
  components: {
    Breadcrumb,
    VideoPlayer,
    RelatedPlatform
  },
  methods: {
    platformDetailsData() {
      let course_id = { course_id: this.$route.params.id };
      axios.post("/fetch_educational_platform_details", course_id).then(({ data }) => {
        this.status = data.status;
        this.platform = data.data.details;
        this.related_course = data.data.other_courses;
        // console.log(this.platform);
      });
    },
  },
  created() {
    this.platformDetailsData();
    this.auth = this.$store.getters['isAuthenticated'];
  },
  watch: {
    $route: {
      handler: "platformDetailsData",
      immediate: true,
    },
  },
};
</script>

<style></style>
