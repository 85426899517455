<template>
  <Header />
  <Features />
  <AboutDoctor />
  <PlatForm />
  <Podcast />
  <SpecialAdvice />
  <Store />
  <CenterConsult />
  <Doctor />
  <Blogs />
</template>

<script>
import Header from "@/components/include/HomePage/header.vue";
import Features from "@/components/include/HomePage/Features.vue";
import AboutDoctor from "@/components/include/HomePage/AboutDoctor.vue";
import PlatForm from "@/components/include/HomePage/platform.vue";
import Podcast from "@/components/include/HomePage/podcast.vue";
import SpecialAdvice from "@/components/include/HomePage/SpecialAdvice.vue";
import Store from "@/components/include/HomePage/store.vue";
import CenterConsult from '@/components/include/HomePage/CenterConsult.vue';
import Doctor from '@/components/include/HomePage/doctor.vue';
import Blogs from '@/components/include/HomePage/blogs.vue';

export default {
  components: {
    Header,
    Features,
    AboutDoctor,
    PlatForm,
    Podcast,
    SpecialAdvice,
    Store,
    CenterConsult,
    Doctor,
    Blogs
  }
}
</script>

<style>

</style>