<template>
  <DoctorDetailsHeader :doctor="doctor" />
  <Certificates :certificates="certificates" />
  <Experiences :experiences="experiences" />
  <SpecialAdvice />
</template>

<script>
import axios from "axios";
import DoctorDetailsHeader from "@/components/include/doctor/DoctorDetailsHeader.vue";
import Certificates from "@/components/include/doctor/certificatesDoctor.vue";
import Experiences from "@/components/include/doctor/experiencesDoctor.vue";
import SpecialAdvice from "@/components/include/HomePage/SpecialAdvice.vue";

export default {
  data() {
    return {
      doctor: {},
      certificates: {},
      experiences: []
    }
  },
  components: {
    DoctorDetailsHeader,
    Certificates,
    Experiences,
    SpecialAdvice,
  },
  methods: {
    doctorDetailsData() {
      let doctor_id = { doctor_id: this.$route.params.id };
      axios.post("/doctor_details", doctor_id).then(({ data }) => {
        this.status = data.status;
        this.doctor = data.data;
        this.certificates = data.data.certificates;
        this.experiences = data.data.experiences;
        // console.log(this.experiences);
      });
    },
  },
  created() {
    this.doctorDetailsData();
  }
};
</script>

<style></style>
