<template>
  <div class="store_card">
    <router-link :to="`/store/${store.id}`" class="image_card">
      <img :src="store.image" :alt="store.title" @error="setDefaultImage">
    </router-link>
    <div class="content">
      <h3 class="title">{{store.title}}</h3>
      <p class="price">{{store.price}} <span class="currency">{{$t("currency")}}</span></p>
      <a :href="store.pdf" v-if="store.IsBuyed == true" download="download" target="_blank" class="btn download_btn"><i class="fa-solid fa-download"></i> {{$t("download_book")}}</a>
      <router-link :to="`/book/payment/${store.id}`" v-if="store.IsBuyed == false && auth == true" class="btn download_btn">{{$t("get_book_now")}}</router-link>
      <button type="submit" v-else-if="auth == false" class="btn get_course_btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("get_book_now")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["store"],
  data() {
    return {
      defaultImageUrl: localStorage.getItem('defaultImage'),
      auth: false,
    };
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
  }
}
</script>

<style>

</style>