<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <section class="consult">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" v-for="(consult, index) in consults" :key="index">
                    <consultCard :consult="consult" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import consultCard from '@/components/include/Consult/ConsultCard.vue';
import { consultData } from "@/api/page/consult/consulting.js";

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("consulting"),
                subTitle: "",
                link: "/platform"
            },
            consults: []
        }
    },
    components: {
        Breadcrumb,
        consultCard
    },
    mounted() {
        consultData().then((response) => {
            this.status = response.data.status;
            this.consults = response.data.data;
            // console.log(this.consults)
            return response;
        });
    },
}
</script>

<style scoped>
    .card_platform {
        margin-bottom: 3rem;
    }
    .platform {
        background-image: none !important;
    }
    @media only screen and (max-width: 991.98px) {
        .platform .card_platform {
            width: 100% !important;
            margin: 0 0 2rem !important;
        }
    }
</style>