<template>
  <router-link :to="`/podcast/${podcast.id}`" class="podcast_card">
    <div class="d-flex align-items-center">
        <img :src="podcast.image" :alt="podcast.title" @error="setDefaultImage">
        <div class="data">
            <p class="title">{{podcast.title}}</p>
            <span class="date">{{podcast.date}}</span>
        </div>
    </div>
    <p class="text" v-html="podcast.text.substring(0, 50) + ' ...'" ></p>
    <span class="minutes">{{podcast.mintues}} {{$t("min")}} <i class="fa-solid fa-circle-play"></i></span>
  </router-link>
</template>

<script>
export default {
    props: ["podcast"],
     data() {
        return {
            defaultImageUrl: localStorage.getItem('defaultImage'),
        };
    },
    methods: {
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },
    },
}
</script>

<style>

</style>