<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="details_platform">
    <div class="container">
      <div class="row">
        <h1 class="title">{{ lesson.title }}</h1>
        <video-player
          :src="lesson.video"
          :poster="lesson.image"
          controls
          :loop="true"
          :volume="0.6"
          download="false"
        />
      </div>
      <Lessons :lessons="lessons" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
// import { platformDetailsData } from "@/api/page/platform/details.js";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import Lessons from "../lessons/lessons.vue";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("lesson"),
        subTitle: "",
        link: "/lesson",
      },
      lesson: {},
      lessons: []
    };
  },
  components: {
    Breadcrumb,
    VideoPlayer,
    Lessons
  },
  methods: {
    fetchLessonDetails() {
      let lesson_id = { lesson_id: this.$route.params.id };
      axios
        .post("/fetch_lesson_details", lesson_id)
        .then(({ data }) => {
          this.status = data.status;
          this.lesson = data.data.details;
          this.lessons = data.data.other_lessons;
          // console.log(this.platform);
        });
    },
  },
  created() {
    this.fetchLessonDetails();
  },
  watch: {
    $route: {
      handler: "fetchLessonDetails",
      immediate: true,
    },
  },
};
</script>

<style></style>
