<template>
  <navbar />
    <router-view></router-view>
  <Footer />
</template>

<script>
import axios from "axios";
import router from "../router/router.js";
import navbar from './components/layouts/navbar.vue';
import Footer from './components/layouts/footer.vue';
require("../src/assets/scss/style.min.css");

export default {
  name: 'App',
  components: {
    navbar,
    Footer,
  },
  created() {
    localStorage.setItem("defaultImage", require('./assets/media/placeholder.png'));

    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    axios.interceptors.response.use(null, function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user_type");
        router.push("/");
      }
      return Promise.reject(error);
    });

    
    // Close inspect 

    // document.addEventListener("contextmenu", (event) => event.preventDefault());
    // document.onkeydown = function (e) {
    //   if (event.keyCode == 123) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
    //     return false;
    //   }
    // }
  },
}
</script>