<template>
  <li>
    <router-link :to="`/stores/${category.id}`">{{category.title}}</router-link>
  </li>
</template>

<script>
export default {
    props: ["category", "index"]
};
</script>

<style></style>
