<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <section class="platform">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" v-for="(card, index) in platforms" :key="index">
                    <platformCard :card="card" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import platformCard from '@/components/include/platform/platformCard.vue';
import { platformProfileData } from "@/api/profile/platform.js";

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("educational_platform"),
                subTitle: "",
                link: ""
            },
            platforms: []
        }
    },
    components: {
        Breadcrumb,
        platformCard
    },
    mounted() {
        platformProfileData().then((response) => {
            this.status = response.data.status;
            this.platforms = response.data.data;
            // console.log(this.platforms)
            return response;
        });
    },
}
</script>

<style scoped>
    .card_platform {
        margin-bottom: 3rem;
    }
    .platform {
        background-image: none !important;
    }
    @media only screen and (max-width: 991.98px) {
        .platform .card_platform {
            width: 100% !important;
            margin: 0 0 2rem !important;
        }
    }
</style>