<template>
  <section class="about_doctor">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12" data-aos="fade-left"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false">
                <img :src="about.image" alt="about_doctor" @error="setDefaultImage">
            </div>
            <div class="col-lg-6 col-md-12 col-12" data-aos="fade-right"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false">
                <div class="content">
                    <h4 class="head_section">{{$t("about_doctor")}}</h4>
                    <h1 class="title_section">{{about.title}}</h1>
                    <h5 class="text_section">{{about.text}}</h5>
                    <PrimaryButton :PrimaryButtonLink="PrimaryButtonLink" :PrimaryButtonText="PrimaryButtonText" />
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { aboutData } from "@/api/index/about.js";
import PrimaryButton from "@/components/include/buttons/PrimaryButton.vue";

export default {
  data() {
    return {
      PrimaryButtonLink: "about",
      PrimaryButtonText: this.$t("know_more"),
      status: "",
      about: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    PrimaryButton,
  },
  mounted() {
    aboutData().then((response) => {
      this.status = response.data.status;
      this.about = response.data.data;
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>