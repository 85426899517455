<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="store">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12" v-for="(store, index) in stores" :key="index">
          <storeCard :store="store" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import storeCard from "@/components/include/store/storeCard.vue";
import { bookData } from "@/api/profile/book.js";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("store"),
        subTitle: "",
        link: "",
      },
      stores: [],
    };
  },
  components: {
    Breadcrumb,
    storeCard
  },
  created() {
    bookData().then((response) => {
      this.status = response.data.status;
      this.stores = response.data.data;
      //   console.log(this.categories)
      return response;
    });
  },
};
</script>


<style></style>
