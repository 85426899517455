<template>
  <!-- start footer section -->
  <footer>
    <div class="container">
      <img src="@/assets/media/logo.png" class="logo" alt="logo" />
      <p class="title" v-html="text.brief_text"></p>
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/" class="nav-link">{{ $t("home") }}</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" class="nav-link">{{
            $t("about_doctor")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/podcast" class="nav-link">{{
            $t("podcast")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/platform" class="nav-link">{{
            $t("educational_platform")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/stores/0" class="nav-link">{{
            $t("store")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/blogs" class="nav-link">{{
            $t("blogs")
          }}</router-link>
        </li>
      </ul>
      <SocialIcon />
      <p class="rights">{{$t("rights")}}</p>
    </div>
  </footer>
  <!-- end footer section -->
</template>

<script>
import SocialIcon from '../include/social/socialicon.vue';
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      text: {},
    };
  },
  components: {
    SocialIcon
  },
  mounted() {
    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
};
</script>
