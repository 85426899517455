<template>
  <lottie-player
    src="https://assets1.lottiefiles.com/packages/lf20_tutvdkg0.json"
    background="transparent"
    speed="1"
    style="width: 500px; height: 500px"
    loop
    autoplay
  ></lottie-player>
</template>
