<template>
  <div class="current_podcast_card">
    <div class="d-flex align-items-center justify-content-between">
        <div>
            <img :src="podcast.image" :alt="podcast.title" @error="setDefaultImage">
            <div class="data">
                <p class="title">{{podcast.title}}</p>
                <span class="date">{{podcast.date}}</span>
                <span class="minutes" v-if="podcast.IsBuyed == false">{{$t("buy_podcast")}} <i class="fa-solid fa-circle-play"></i></span>
                <audio controlsList="nodownload" v-if="podcast.IsBuyed == true" :src="podcast.audio" controls></audio>
            </div>
        </div>
        <router-link :to="`/podcast/payment/${podcast.id}`" v-if="podcast.IsBuyed == false && auth == true" class="btn get_course_btn PrimaryButton">
            {{ $t("get_podcast_now") }}
        </router-link>
        <button type="submit" v-else-if="auth == false" class="btn get_course_btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("get_podcast_now")}}</button>
    </div>
    <p class="text">{{podcast.text}}</p>
  </div>
</template>

<script>
export default {
    props: ["podcast"],
     data() {
        return {
            defaultImageUrl: localStorage.getItem('defaultImage'),
            PodcastForm: {
                podcast_id: this.$route.params.id,
                payment_method_id: 1,
            },
            auth: false,
        };   
    },
    methods: {
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },
    },
    created() {
        this.auth = this.$store.getters['isAuthenticated'];
    },
}
</script>

<style scoped>
    audio {
        display: block;
        margin-top: 1rem;
        width: 400px;
    }
</style>