<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="podcasts">
    <div class="container">
        <currentPodcastCard :podcast="podcast" />

        <!-- Anthor podcast -->
        <podcastCard v-for="(podcast, index) in other_podcasts" :key="index" :podcast="podcast" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
// import { podcastsDetailsData } from "@/api/podcasts/details.js";
import currentPodcastCard from '@/components/include/podcast/current_podcast_card.vue';
import podcastCard from '@/components/include/podcast/podcast_card.vue';

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("podcast"),
                subTitle: "",
                link: "",
            },
            podcast: {},
            other_podcasts: [],
        }
    },
    components: {
        Breadcrumb,
        currentPodcastCard,
        podcastCard,
    },
    methods: {
        podcastsDetailsData() {
            let podcast_id = {podcast_id : this.$route.params.id}
            axios.post("/fetch_podcast_details", podcast_id).then(({ data }) => {
                this.status = data.status;
                this.podcast = data.data.details;
                this.other_podcasts = data.data.other_podcasts;
                // console.log(this.podcast);
            });
        }
    },
    created() {
        this.podcastsDetailsData();
    },
    watch: {
        $route: {
        handler: "podcastsDetailsData",
        immediate: true,
        },
    },
}
</script>

<style>

</style>