<template>
  <section class="why">
    <div class="container">
        <h1 class="title" v-html="text.website_reasons_text"></h1>
        <!-- <p class="text">{{why.text}}</p> -->
        <div class="why_list">
            <div class="why_card" v-for="(list, index) in why" :key="index">
              <img :src="list.image" :alt="list.title" @error="setDefaultImage">
              <p class="title">{{list.title}}</p>
              <span class="text">{{list.text}}</span>
            </div>
        </div>
        <!-- <router-link to="/about" class="btn">تعرف على سيرتي كاملة</router-link> -->
    </div>
  </section>
</template>

<script>
import { whyData } from "@/api/about/why.js";
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      status: "",
      why: [],
      text: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  mounted() {
    whyData().then((response) => {
      this.status = response.data.status;
      this.why = response.data.data;
      // console.log(this.why)
      return response;
    });

    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  }
};
</script>