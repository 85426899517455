<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <form v-on:submit.prevent="SubmitForm" class="reservation">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 col-12">
                    <div class="reservation_data">
                        <p class="label">{{$t("consultation_type")}}</p>
                        <div class="select_consult_type">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="selectConsultType" id="ConsultType" value="1" v-model="Form.contact_type" @change="RemoveValueDate" hidden> 
                                <label class="form-check-label" for="ConsultType">
                                    <i class="fa-solid fa-video"></i>
                                    <p>{{$t("online_consult")}}</p>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="selectConsultType" id="ConsultType1" value="2" v-model="Form.contact_type" @change="RemoveValueDate" hidden> 
                                <label class="form-check-label" for="ConsultType1">
                                    <i class="fa-solid fa-building-columns"></i>
                                    <p>{{$t("center_consult")}}</p>
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                          <label class="label_form" for="">{{$t("choose_day")}}</label>
                          <input type="date" name="" id="" class="form-control" :min="minDate" v-model="Form.date" @change="getTimes($event)">
                        </div>
                        <div class="select_day_time" v-if="times.length">
                            <label for="" class="label_form">{{$t("choose_time")}}</label>
                            <div class="form-check form-check-inline" v-for="(time, index) in times" :key="index">
                                <input class="form-check-input" type="radio" :value="time.id" v-model="Form.doctor_day_time_id" name="selectDay" :id="`selectDay${time.id}`" hidden> 
                                <label class="form-check-label" :for="`selectDay${time.id}`">{{time.from_time}} / {{time.to_time}}</label>
                            </div>
                        </div>
                        <div class="select_day_time" v-if="!times.length && Form.date != ''">
                            <h4>{{$t("There are no appointments available please choose another day")}}</h4>
                        </div>
                        <div class="form-group">
                          <label class="label_form" for="">{{$t("patient_notes")}}</label>
                          <textarea name="" class="form-control" id="" v-model="Form.patient_notes" cols="30" rows="5"></textarea>
                        </div>
                    </div>
                    <div class="personal_data">
                        <p class="label">{{$t("personal_info")}}</p>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <i class="fa-regular fa-user"></i>
                                    <input type="text" name="" v-model="Form.user_name" id="" class="form-control" :placeholder="$t('full_name')">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <i class="fa-solid fa-mobile-screen"></i>
                                    <input type="text" name="" v-model="Form.user_phone" id="" class="form-control" :placeholder="$t('phone')">
                                </div>
                            </div>
                            <div class="form-check form-switch">
                                <label class="form-check-label" for="flexSwitchCheckChecked">{{$t("reservation_another_person")}}</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="Form.for_another_person">
                            </div>
                            <div class="row mt-4" v-if="Form.for_another_person == true">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <i class="fa-regular fa-user"></i>
                                        <input type="text" name="" v-model="Form.another_person_name" id="" class="form-control" :placeholder="$t('full_name')">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <i class="fa-solid fa-mobile-screen"></i>
                                        <input type="text" name="" v-model="Form.another_person_phone" id="" class="form-control" :placeholder="$t('phone')">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" v-if="auth == true" class="btn PrimaryButton">{{$t("submit_reservation")}}</button>
                                <button type="submit" v-else-if="auth == false" class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("submit_reservation")}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-12">
                    <div class="doctor_data">
                        <p class="label">{{$t("physician")}}</p>
                        <div class="card_doctor_data">
                            <img :src="doctor.image" alt="doctor" @error="setDefaultImage">
                            <div class="data">
                                <p class="name">{{doctor.name}}</p>
                                <p class="text">{{doctor.mini_description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
// import { reservationDetailsData } from "@/api/profile/reservationDetails.js";
import { doctorDetailsData } from "@/api/doctor/doctorDetails.js";

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("consulting"),
                subTitle: "",
                link: "/platform"
            },
            Form: {
                doctor_id: this.$route.params.doctor_id,
                consultation_id: this.$route.params.consult_id,
                doctor_day_time_id: "",
                date: "",
                user_name: JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).name,
                user_phone: JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).phone,
                another_person_name: "",
                another_person_phone: "",
                contact_type: "",
                // images: [],
                patient_notes: "",
                for_another_person: false,
            },
            doctor: {},
            times: [],
            minDate: new Date().toISOString().slice(0, 10), // set the min date to today
            defaultImageUrl: localStorage.getItem('defaultImage'),
            auth: false,
        }
    },
    components: {
        Breadcrumb,
    },
    created() {
        this.auth = this.$store.getters['isAuthenticated'];
        // console.log(this.auth)

        let doctor_id = {doctor_id : this.$route.params.doctor_id}
        doctorDetailsData(doctor_id).then((response) => {
            this.status = response.data.status;
            this.doctor = response.data.data;
            // console.log(this.doctor)
            return response;
        });
        // console.log(this.Form.user_name)
    },
    methods: {
        SubmitForm() {
            if (this.auth == false) {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_login"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else if (this.Form.contact_type == "") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_choose_consultation_type"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else if (this.Form.date == "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("please_choose_date"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
            else if (this.Form.doctor_day_time_id == "") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_choose_day_time"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else if (this.Form.user_name == "") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_choose_user_name"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else if (this.Form.user_phone == "") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_choose_user_phone"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: this.$t("please_complete_data"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                localStorage.setItem("reservation", JSON.stringify(this.Form));
                this.$router.push(`/reservation/payment/${this.$route.params.doctor_id}`)
            }
        },
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },

        RemoveValueDate() {
            this.Form.date = "",
            this.times = []
            // console.log(this.Form.date)
        },

        // get times by date
        getTimes(e) {
            let date = e.target.value;

            const formData = new FormData();
            formData.append("doctor_id", this.$route.params.doctor_id);
            formData.append("date", date);
            formData.append("contact_type", this.Form.contact_type);

            const newLocal = this.$i18n.locale;
            axios.defaults.headers.common["Accept-Language"] = newLocal;

            if (this.Form.date != "" && this.Form.contact_type != "") {
                axios.post("/filter_doctor_times_by_date", formData).then(({ data }) => {
                    this.times = data.data;
                    // console.log(this.times)
                });
            }
        },
        // get times by date
    },
}
</script>

<style scoped>
    input[type="date"] {
        width: 95%;
        margin: 0 auto;
    }
    textarea {
        resize: none;
        width: 95%;
        margin: 0 auto;
    }
</style>