<template>
  <swiper
    :slidesPerView="3"
    :spaceBetween="30"
    :navigation="true"
    :modules="modules"
    :breakpoints="{
      300: {
        slidesPerView: 1,
        // slidesPerGroup: 1,
        loopFillGroupWithBlank: false,
      },
      768: {
        slidesPerView: 2,
        loopFillGroupWithBlank: false,
      },
      1024: {
        slidesPerView: 3,
        loopFillGroupWithBlank: true,
      },
    }"
    class="mySwiper"
  >
    <swiper-slide
      v-for="(podcast, index) in mostlistened_podcasts"
      :key="index"
    >
      <podcastCard :podcast="podcast" />
    </swiper-slide>
  </swiper>
</template>

<script>
import podcastCard from "./podcast_card.vue";
import { podcastsData } from "@/api/podcasts/podcasts.js";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Navigation } from 'swiper';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("podcast"),
        subTitle: "",
        link: "",
      },
      mostlistened_podcasts: [],
    };
  },
  components: {
    podcastCard,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  created() {
    podcastsData().then((response) => {
      this.status = response.data.status;
      this.mostlistened_podcasts = response.data.data.mostlistened_podcasts;
      // console.log(this.podcasts)
      return response;
    });
  },
};
</script>

<style scoped>
.podcast_card {
    padding: 1.5rem !important;
    border-bottom: 1px solid #e1e1e1 !important;
    border: 1px solid #e1e1e1;
}
</style>
