<template>
  <ul class="socialicon">
    <li>
      <a target="_blank" v-if="setting.whatsapp != ''" :href="setting.whatsapp"
        ><i class="fab fa-whatsapp icon"></i
      ></a>
    </li>
    <li>
      <a target="_blank" v-if="setting.facebook != ''" :href="setting.facebook"
        ><i class="fa-brands fa-facebook-f icon"></i
      ></a>
    </li>
    <li>
      <a target="_blank" v-if="setting.twitter != ''" :href="setting.twitter"
        ><i class="fa-brands fa-twitter icon"></i
      ></a>
    </li>
    <li>
      <a target="_blank" v-if="setting.instagram != ''" :href="setting.instagram"
        ><i class="fa-brands fa-instagram icon"></i
      ></a>
    </li>
    <li>
      <a target="_blank" v-if="setting.linkedin != ''" :href="setting.linkedin"
        ><i class="fa-brands fa-linkedin icon"></i
      ></a>
    </li>
    <li>
      <a target="_blank" v-if="setting.youtube != ''" :href="setting.youtube"
        ><i class="fa-brands fa-youtube icon"></i
      ></a>
    </li>
  </ul>
</template>

<script>
import { settingData } from "@/api/index/fetchSetting.js";

export default {
  data() {
    return {
      setting: {}
    }
  },
  mounted() {
    settingData().then((response) => {
      this.status = response.data.status;
      this.setting = response.data.data;
      // console.log(this.why)
      return response;
    });
  }
};
</script>

<style></style>
