<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <form v-if="reservation" v-on:submit.prevent="SubmitForm" class="reservation">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <div class="reservation_data">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-credit-card credit_svg"></i>
              <div class="data_payment">
                <p class="title">{{$t("submit_reservation")}}</p>
                <p class="text">{{$t("Choose the payment method you want to continue with")}}</p>
              </div>
            </div>
            <div class="select_consult_type">
              <div class="form-check form-check-inline radio_payment" v-for="(payment, index) in payments" :key="index">
                <input class="form-check-input" type="radio" name="selectConsultId" :id="`ConsultType${payment.id}`" :value="payment.id" v-model="payment_method_id" hidden> 
                <!-- <input class="form-check-input" type="radio" name="selectConsultType" :id="`ConsultType${payment.id}`" :value="payment.type" v-model="payment_type" hidden>  -->
                <label class="form-check-label" :for="`ConsultType${payment.id}`">
                  <img :src="payment.image" :alt="payment.title">
                  <h6 class="title">{{payment.title}}</h6>
                  <p class="text">{{payment.text}}</p>
                </label>
              </div>
            </div>
            <button type="submit" class="btn PrimaryButton w-100">{{$t("submit_reservation")}}</button>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="doctor_data">
            <p class="label">{{ $t("physician") }}</p>
            <div class="card_doctor_data">
              <img :src="doctor.image" alt="doctor" @error="setDefaultImage" />
              <div class="data">
                <p class="name">{{ doctor.name }}</p>
                <p class="text">{{ doctor.mini_description }}</p>
              </div>
            </div>
            <div class="reservation_info">
              <div class="d-flex align-items-center justify-content-between">
                <p>{{$t("reservation_details")}}</p>
              </div>
              <span>
                <i class="fa-solid fa-video"></i>
                {{
                  reservation.contact_type == 1
                    ? $t("center_statement")
                    : reservation.contact_type == 2
                    ? $t("online_statement")
                    : ""
                }}
              </span>
              <span>
                <i class="fa-solid fa-clock"></i>
                {{ reservation.date }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import { doctorDetailsData } from "@/api/doctor/doctorDetails.js";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("payment"),
        subTitle: "",
        link: ""
      },
      status: true,
      doctor: {},
      defaultImageUrl: localStorage.getItem("defaultImage"),
      reservation: JSON.parse(localStorage.getItem("reservation")),
      payments: [],
      payment_method_id: "",
      payment_type: ""
    };
  },
  components: {
    Breadcrumb
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("reservation");
    next();
    // console.log("test");
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
    fetchPaymentMethods() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let offline = {offline: this.reservation.contact_type == 2 ? 1 : this.reservation.contact_type == 1 ? 0 : 0 }
      axios.post("/fetch_payment_methods", offline).then(({ data }) => {
        this.status = data.status;
        this.payments = data.data;
        // console.log(this.payments)
      });
    },
    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin

      const formData = new FormData();
      Object.entries(this.reservation).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("for_another_person", this.reservation.for_another_person == 0 || this.reservation.for_another_person == false ? 0 : this.reservation.for_another_person == 1 || this.reservation.for_another_person == true ? 1 : 0);
      formData.append("payment_method_id", this.payment_method_id);

      axios
          .post("/make_reservation", formData)
          .then((data) => {
          if (data.data.status == true) {
              //Perform Success Action
              Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            for (let index = 0; index < this.payments.length; index++) {
              if (this.payments[index].id == this.payment_method_id) {
                if (this.payments[index].type == 0 || this.payments[index].type == 2) {
                  this.$router.push({ path: "/reservations" });
                }
                else if (this.payments[index].type == 1) {
                  this.$router.push({ path: "/reservations" });
                  window.open('https://accept.paymobsolutions.com/api/acceptance/iframes/151432?payment_token=ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6VXhNaUo5LmV5SnZjbVJsY2w5cFpDSTZPRGswTlRJM015d2lkWE5sY2w5cFpDSTZPVEl4Tmpnc0ltSnBiR3hwYm1kZlpHRjBZU0k2ZXlKbWFYSnpkRjl1WVcxbElqb2lRMnhwWm1admNtUWlMQ0pzWVhOMFgyNWhiV1VpT2lKT2FXTnZiR0Z6SWl3aWMzUnlaV1YwSWpvaVJYUm9ZVzRnVEdGdVpDSXNJbUoxYVd4a2FXNW5Jam9pT0RBeU9DSXNJbVpzYjI5eUlqb2lORElpTENKaGNHRnlkRzFsYm5RaU9pSTRNRE1pTENKamFYUjVJam9pU21GemEyOXNjMnRwWW5WeVoyZ2lMQ0p6ZEdGMFpTSTZJbFYwWVdnaUxDSmpiM1Z1ZEhKNUlqb2lRMUlpTENKbGJXRnBiQ0k2SW1Oc1lYVmtaWFIwWlRBNVFHVjRZUzVqYjIwaUxDSndhRzl1WlY5dWRXMWlaWElpT2lJck9EWW9PQ2s1TVRNMU1qRXdORGczSWl3aWNHOXpkR0ZzWDJOdlpHVWlPaUl3TVRnNU9DSXNJbVY0ZEhKaFgyUmxjMk55YVhCMGFXOXVJam9pVGtFaWZTd2lhVzUwWldkeVlYUnBiMjVmYVdRaU9qRTFNall4TkN3aVlXMXZkVzUwWDJObGJuUnpJam94TURBc0ltTjFjbkpsYm1ONUlqb2lSVWRRSWl3aWJHOWphMTl2Y21SbGNsOTNhR1Z1WDNCaGFXUWlPbVpoYkhObExDSmxlSEFpT2pNMk1EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01UWXhNek01T1RBek55d2ljRzFyWDJsd0lqb2lORFV1TWpRM0xqUTBMakU1TnlKOS5UNV9fTTM1WFh4RE5CLXlPNFdrNUhfOXZPcENwVGtoSHpDejQ2TUZJOG5WclhXc3NIOUpvdFNFNEdPNnVkNzFwbnpEa1pyazNmVWhXVFNSYWttQy1UZw==', '_blank');
                }
              }
            }
            // console.log(this.payment_type)
          } else {
              // console.log(data);
              //Perform Success Action
              Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
          }
          })
          .catch((error) => {
          Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 2000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
          })
          .finally(() => (this.loading = false));
      {
          //Perform action in always
      }
    },
  },
  created() {
    let doctor_id = { doctor_id: this.$route.params.doctor_id };
    doctorDetailsData(doctor_id).then((response) => {
      this.status = response.data.status;
      this.doctor = response.data.data;
      // console.log(this.doctor)
      return response;
    });
    this.fetchPaymentMethods();
  },
};
</script>

<style scoped>
  .select_consult_type {
    flex-wrap: wrap;
  }
  .select_consult_type .radio_payment{
    width: 47% !important;
    text-align: start;
  }
  .select_consult_type .radio_payment .form-check-label {
    text-align: start !important;
    padding: 1.5rem 2rem !important;
  }
  .select_consult_type .radio_payment .form-check-label img {
    display: block;
    background-color: #fff;
    width: 90px;
    height: 60px;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 600px) {
    .select_consult_type .radio_payment{
      width: 100% !important;
      margin-bottom: 0 !important;
    }
  }
</style>
