<template>
  <section class="center_consult">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12">
              <img :src="center.image" :alt="center.title" class="image" @error="setDefaultImage">
            </div>
            <div class="col-lg-6 col-12">
                <h1 class="title">{{center.title}}</h1>
                <p class="text">{{center.text}}</p>
                <div class="list_consult" v-for="(list, index) in center.features" :key="index"
                data-aos="fade-up-right"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false">
                    <img :src="list.image" :alt="list.title" class="icon" @error="setDefaultImage">
                    <div class="content">
                        <p class="title">{{list.title}}</p>
                        <p class="text">{{list.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { CenterConsultData } from "@/api/index/CenterConsult.js";
import PrimaryButton from "@/components/include/buttons/PrimaryButton.vue";

export default {
  data() {
    return {
      PrimaryButtonLink: "about",
      PrimaryButtonText: this.$t("know_more"),
      status: "",
      center: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    PrimaryButton,
  },
  mounted() {
    CenterConsultData().then((response) => {
      this.status = response.data.status;
      this.center = response.data.data;
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>