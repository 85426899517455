import { createRouter, createWebHistory } from "vue-router";
import store from '../src/store/index.js';

//import website
import indexPage from "../src/views/index.vue";
import aboutPage from "../src/views/about.vue";
import podcastPage from "../src/views/podcast/podcast.vue";
import podcastDetailsPage from "../src/views/podcast/details.vue";
import platformsPage from "../src/views/platform/platform.vue";
import platformDetailsPage from "../src/views/platform/details.vue";
import centerPage from "../src/views/center.vue";
import storePage from "../src/views/store/store.vue";
import storeDetailsPage from "../src/views/store/details.vue";
import blogsPage from "../src/views/blogs/blogs.vue";
import blogDetailsPage from "../src/views/blogs/details.vue";
import contactPage from "../src/views/contact.vue";
import doctorsPage from "../src/views/doctor/doctors.vue";
import doctorDetailsPage from "../src/views/doctor/doctor_details.vue";
import consultPage from "../src/views/consult/Consulting.vue";
import consultDoctorPage from "../src/views/consult/ConsultDoctor.vue";
import consultDoctorReservationPage from "../src/views/consult/consultDoctorReservation.vue";

import accountpage from "../src/views/profile/account.vue";
import reservationspage from "../src/views/profile/reservations.vue";
import reservationsDetailspage from "../src/views/profile/reservations_details.vue";
import myPodcastpage from "../src/views/profile/podcast.vue";
import paymentPodcastpage from "../src/views/podcast/payment.vue";
import paymentReservation from "../src/views/consult/payment.vue"
import myPlatformpage from "../src/views/profile/platform.vue";
import paymentPlatformpage from "../src/views/platform/payment.vue";
import myBookpage from "../src/views/profile/book.vue";
import paymentBookpage from "../src/views/store/payment.vue";
import coursePage from "../src/views/platform/course.vue";
import lessonPage from "../src/views/lessons/lesson_details.vue";

// import NotFound from "./components/pages/error";


const routes = [
    // Website routes
    { path: "/", component: indexPage, name: "index" },
    { path: "/about", component: aboutPage, name: "about" },
    { path: "/podcast", component: podcastPage, name: "podcast" },
    { path: "/podcast/:id", component: podcastDetailsPage, name: "podcastDetails" },
    { path: "/platform", component: platformsPage, name: "platforms" },
    { path: "/platform/:id", component: platformDetailsPage, name: "platformDetails" },
    { path: "/center", component: centerPage, name: "center" },
    { path: "/stores/:id", component: storePage, name: "store" },
    { path: "/store/:id", component: storeDetailsPage, name: "storeDetails" },
    { path: "/blogs", component: blogsPage, name: "blogs" },
    { path: "/blog/:id", component: blogDetailsPage, name: "blogDetails" },
    { path: "/contact", component: contactPage, name: "contact" },
    { path: "/doctors", component: doctorsPage, name: "doctors" },
    { path: "/doctor/:id", component: doctorDetailsPage, name: "doctorDetails" },
    { path: "/consult", component: consultPage, name: "consult" },
    { path: "/consult/:id", component: consultDoctorPage, name: "consultDoctor" },
    { path: "/consult/:consult_id/doctor/:doctor_id", component: consultDoctorReservationPage, name: "consultDoctorReservation" },
    
    { path: "/account", component: accountpage, name: "account", meta: { requiresAuth: true } },
    { path: "/reservations", component: reservationspage, name: "reservations", meta: { requiresAuth: true } },
    { path: "/reservation/:id", component: reservationsDetailspage, name: "reservation", meta: { requiresAuth: true } },
    { path: "/reservation/payment/:doctor_id", component: paymentReservation, name: "payment_reservation", meta: { requiresAuth: true } },
    { path: "/my_podcast", component: myPodcastpage, name: "my_podcast", meta: { requiresAuth: true } },
    { path: "/podcast/payment/:id", component: paymentPodcastpage, name: "paymentPodcast", meta: { requiresAuth: true } },
    { path: "/my_platform", component: myPlatformpage, name: "my_platform", meta: { requiresAuth: true } },
    { path: "/platform/payment/:id", component: paymentPlatformpage, name: "paymentPlatform", meta: { requiresAuth: true } },
    { path: "/my_book", component: myBookpage, name: "book", meta: { requiresAuth: true } },
    { path: "/book/payment/:id", component: paymentBookpage, name: "paymentBook", meta: { requiresAuth: true } },
    { path: "/course/:id", component: coursePage, name: "course", meta: { requiresAuth: true } },
    { path: "/lesson/:id", component: lessonPage, name: "lesson", meta: { requiresAuth: true } },
    // { path: "/404", component: NotFound},
    // { path: "/:catchAll(.*)", redirect: "/404"},

];

const router = createRouter({
    routes,
    history: createWebHistory(),
    hashbang: false,
    mode: "history",
    linkActiveClass: "active",
    // linkExactActiveClass: "exact-active",
    scrollBehavior() {
        // Scroll to the top of the page
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
        next();
        return;
        }
        next("/");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
        next("/account");
        return;
        }
        next();
    } else {
        next();
    }
});  

export default router;