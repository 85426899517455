<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <section class="blogs">
        <div class="container">
            <router-link :to="`/blog/${fisrtBlog.id}`" class="row fisrtBlog">
                <div class="col-lg-6 col-md-12 col-12">
                    <img :src="fisrtBlog.image" :alt="fisrtBlog.title" @error="setDefaultImage">
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <span class="date">{{fisrtBlog.date}}</span>
                    <span class="read_number">{{fisrtBlog.read_number}} {{$t("number_of_read")}}</span>
                    <h3 class="title">{{fisrtBlog.title}}</h3>
                    <p class="text" v-html="textFisrtBlog.substring(0, 700) + ' ...'"></p>
                </div>
            </router-link>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12" v-for="(blog, index) in blogs.data" :key="index">
                    <CardBlog :blog="blog" />
                </div>
            </div>
            <div class="row">
                <pagination
                    :data="blogs"
                    class="mx-auto d-flex align-items-center justify-content-center pagination"
                    @pagination-change-page="blogsData"
                >
                    <span slot="prev-nav">&lt;</span>
                    <span slot="next-nav">&gt;</span>
                </pagination>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import CardBlog from '@/components/include/blogs/CardBlog.vue'
// import { blogsData } from "@/api/page/blogs/blogs.js";

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("blogs"),
                subTitle: "",
                link: ""
            },
            blogs: [],
            fisrtBlog: {},
            textFisrtBlog: "",
            defaultImageUrl: localStorage.getItem('defaultImage'),
        }
    },
    components: {
        Breadcrumb,
        CardBlog,
        pagination
    },
    methods: {
        blogsData(page = 1) {
            const newLocal = this.$i18n.locale;
            axios.defaults.headers.common["Accept-Language"] = newLocal;
            axios.get("/fetch_blogs?page=" + page).then(({ data }) => {
                this.status = data.status;
                this.blogs = data.data;
                this.fisrtBlog = this.blogs.data.shift();
                this.textFisrtBlog = data.data.data[0].text;
                // console.log(this.blogs)
                // console.log(this.libraries);
            });
        },
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },
    },
    created() {
        this.blogsData();
    },
}
</script>

<style lang="scss" scoped>
    .blogs {
        .fisrtBlog {
            align-items: center;
            margin-bottom: 8rem;
            img {
                display: block;
                width: 90%;
                height: 450px;
                border-radius: 12px;
            }
            .title {
                font-family: "bold";
                color: #11191B;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 95%;
                margin-top: 1rem;
            }
            .text {
                font-family: "regular";
                color: #555D5F;
                margin-top: 2rem;
                width: 95%;
                line-height: 2;
            }
            .date {
                display: inline-block;
                text-align: start;
                padding: 0 0.5rem;
                margin-inline-end: 1.5rem;
                width: fit-content;
                font-family: "regular";
                color: #555d5f;
            }
            .read_number {
                display: inline-block;
                text-align: end;
                padding: 0 0.5rem;
                width: fit-content;
                font-family: "regular";
                color: #555d5f;
            }
        }
    }
</style>