<template>
  <section class="experiences" v-if="experiences.length">
    <div class="container">
        <!-- <h1 class="title">{{experience.title}}</h1>
        <p class="text">{{experience.text}}</p> -->
        <div class="experiences_list">
            <div class="list" v-for="(experience, index) in experiences" :key="index">
              <h4 class="title_list">{{experience.title}}</h4>
              <p class="text_list">{{experience.text}}</p>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["experiences"],
};
</script>