<template>
    <div class="lesson_content">
        <h4>{{$t("lesson_content")}}</h4>
        <cardLesson :lessons="lessons" />
    </div>
</template>

<script>
import cardLesson from './cardLesson.vue';
import axios from "axios";

export default {
    props: ["lessons"],
    components: {
        cardLesson
    },
     methods: {
        platformDetailsData() {
        let course_id = { course_id: this.$route.params.id };
        axios
            .post("/fetch_educational_platform_details", course_id)
            .then(({ data }) => {
            this.status = data.status;
            this.platform = data.data.details;
            // console.log(this.platform);
            });
        },
        fetchLessonsData() {
            let course_id = { course_id: this.$route.params.id };
            axios.post("/fetch_course_lessons", course_id).then(({ data }) => {
                this.status = data.status;
                this.lessons = data.data;
                // console.log(this.lessons);
            });
        },
    },
}
</script>

<style>

</style>