<template>
  <router-link :to="`/consult/${consult.id}`" class="consult_card">
    <img :src="consult.image" :alt="consult.title" @error="setDefaultImage">
    <h4 class="title">{{consult.title}}</h4>
    <p class="price">{{consult.price}} {{$t("currency")}}</p>
  </router-link>
</template>

<script>
export default {
  props: ["consult"],
  data() {
    return {
      defaultImageUrl: localStorage.getItem('defaultImage'),
    }
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  }
}
</script>

<style>

</style>