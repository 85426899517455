<template>
  <div class="related_blog blogs">
    <div class="container">
        <h3 class="title">مقالات مشابهة</h3>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12" v-for="(blog, index) in related_blog" :key="index">
                <CardBlog :blog="blog" />
            </div>
        </div>
    </div>    
  </div>
</template>

<script>
import axios from "axios";
// import { blogsDetailsData } from "@/api/page/blogs/details.js";
import CardBlog from './CardBlog.vue';

export default {
  data() {
    return {
      related_blog: []
    };
  },
  components: {
    CardBlog
  },
  methods: {
    blogsDetailsData() {
      let blog_id = { blog_id: this.$route.params.id };
      axios.post("/blog_details", blog_id).then(({ data }) => {
        this.status = data.status;
        this.related_blog = data.data.similar_blogs;
        // console.log(this.related_blog);
      });
    },
  },
  created() {
    this.blogsDetailsData()
  },
  watch: {
    $route: {
      handler: "blogsDetailsData",
      immediate: true,
    },
  },
};
</script>

<style scoped>
    h3 {
        font-family: "semibold";
        color: #11191B;
    }
</style>
