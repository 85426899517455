<template>
  <Header />
  <Why />
  <Certificates />
  <Experiences />
  <SpecialAdvice />
</template>

<script>
import Header from '@/components/include/AboutPage/Header.vue';
import Why from '@/components/include/AboutPage/Why.vue';
import Certificates from '@/components/include/AboutPage/Certificates.vue';
import Experiences from '@/components/include/AboutPage/Experiences.vue';
import SpecialAdvice from "@/components/include/HomePage/SpecialAdvice.vue";

export default {
    components: {
        Header,
        Why,
        Certificates,
        Experiences,
        SpecialAdvice
    }
}
</script>

<style>

</style>