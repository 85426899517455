<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <form v-on:submit.prevent="getCourse" class="reservation">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <div class="reservation_data">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-credit-card credit_svg"></i>
              <div class="data_payment">
                <p class="title">{{$t("submit_reservation")}}</p>
                <p class="text">{{$t("Choose the payment method you want to continue with")}}</p>
              </div>
            </div>
            <div class="select_consult_type">
              <div class="form-check form-check-inline radio_payment" v-for="(payment, index) in payments" :key="index">
                <input class="form-check-input" type="radio" name="selectConsultId" :id="`ConsultType${payment.id}`" :value="payment.id" v-model="CourseForm.payment_method_id" hidden> 
                <!-- <input class="form-check-input" type="radio" name="selectConsultType" :id="`ConsultType${payment.id}`" :value="payment.type" v-model="payment_type" hidden>  -->
                <label class="form-check-label" :for="`ConsultType${payment.id}`">
                  <img :src="payment.image" :alt="payment.title">
                  <h6 class="title">{{payment.title}}</h6>
                  <p class="text">{{payment.text}}</p>
                </label>
              </div>
            </div>
            <button type="submit" class="btn PrimaryButton w-100">{{$t("submit_reservation")}}</button>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="doctor_data">
            <p class="label">{{ $t("about_course") }}</p>
            <div class="card_doctor_data">
              <img :src="course.image" alt="doctor" @error="setDefaultImage" />
              <div class="data">
                <p class="name">{{ course.title }}</p>
                <p class="text">{{ course.price }} {{$t("currency")}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import { doctorDetailsData } from "@/api/doctor/doctorDetails.js";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("payment"),
        subTitle: "",
        link: ""
      },
      status: true,
      course: {},
      defaultImageUrl: localStorage.getItem("defaultImage"),
      payments: [],
      CourseForm: {
        course_id: this.$route.params.id,
        payment_method_id: "",
      }
    };
  },
  components: {
    Breadcrumb
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("reservation");
    next();
    // console.log("test");
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
    fetchPaymentMethods() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let offline = {offline: 0}
      axios.post("/fetch_payment_methods", offline).then(({ data }) => {
        this.status = data.status;
        this.payments = data.data;
        // console.log(this.payments)
      });
    },
    fetchEducationalPlatformDetails() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let course_id = {course_id: this.$route.params.id};
      axios.post("/fetch_educational_platform_details", course_id).then(({ data }) => {
          this.status = data.status;
          this.course = data.data.details;
          // console.log(this.course)
      });
    },
    getCourse() {
      axios.post("/buy_course", this.CourseForm).then(({ data }) => {
        this.data = data;
        if (this.data.status == true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.data.message,
            timer: 2000,
          });
          for (let index = 0; index < this.payments.length; index++) {
            if (this.payments[index].type == 0 || this.payments[index].type == 2) {
              this.$router.push({ path: "/my_platform" });
            }
            else if (this.payments[index].type == 1) {
              this.$router.push({ path: "/my_platform" });
              window.open('https://accept.paymobsolutions.com/api/acceptance/iframes/151432?payment_token=ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6VXhNaUo5LmV5SnZjbVJsY2w5cFpDSTZPRGswTlRJM015d2lkWE5sY2w5cFpDSTZPVEl4Tmpnc0ltSnBiR3hwYm1kZlpHRjBZU0k2ZXlKbWFYSnpkRjl1WVcxbElqb2lRMnhwWm1admNtUWlMQ0pzWVhOMFgyNWhiV1VpT2lKT2FXTnZiR0Z6SWl3aWMzUnlaV1YwSWpvaVJYUm9ZVzRnVEdGdVpDSXNJbUoxYVd4a2FXNW5Jam9pT0RBeU9DSXNJbVpzYjI5eUlqb2lORElpTENKaGNHRnlkRzFsYm5RaU9pSTRNRE1pTENKamFYUjVJam9pU21GemEyOXNjMnRwWW5WeVoyZ2lMQ0p6ZEdGMFpTSTZJbFYwWVdnaUxDSmpiM1Z1ZEhKNUlqb2lRMUlpTENKbGJXRnBiQ0k2SW1Oc1lYVmtaWFIwWlRBNVFHVjRZUzVqYjIwaUxDSndhRzl1WlY5dWRXMWlaWElpT2lJck9EWW9PQ2s1TVRNMU1qRXdORGczSWl3aWNHOXpkR0ZzWDJOdlpHVWlPaUl3TVRnNU9DSXNJbVY0ZEhKaFgyUmxjMk55YVhCMGFXOXVJam9pVGtFaWZTd2lhVzUwWldkeVlYUnBiMjVmYVdRaU9qRTFNall4TkN3aVlXMXZkVzUwWDJObGJuUnpJam94TURBc0ltTjFjbkpsYm1ONUlqb2lSVWRRSWl3aWJHOWphMTl2Y21SbGNsOTNhR1Z1WDNCaGFXUWlPbVpoYkhObExDSmxlSEFpT2pNMk1EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01EQXdNREF3TURBd01UWXhNek01T1RBek55d2ljRzFyWDJsd0lqb2lORFV1TWpRM0xqUTBMakU1TnlKOS5UNV9fTTM1WFh4RE5CLXlPNFdrNUhfOXZPcENwVGtoSHpDejQ2TUZJOG5WclhXc3NIOUpvdFNFNEdPNnVkNzFwbnpEa1pyazNmVWhXVFNSYWttQy1UZw==', '_blank');
            }
          }
        }
        else if (this.data.status == false) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.data.message,
            timer: 2000,
          });
        }
        // console.log(this.platform);
      }).catch(error => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.response.data.message,
          timer: 2000,
        });
      }); 
    }
  },
  created() {
    this.fetchPaymentMethods();
    this.fetchEducationalPlatformDetails()
  },
};
</script>

<style scoped>
  .select_consult_type {
    flex-wrap: wrap;
  }
  .select_consult_type .radio_payment{
    width: 47% !important;
    text-align: start;
  }
  .select_consult_type .radio_payment .form-check-label {
    text-align: start !important;
    padding: 1.5rem 2rem !important;
  }
  .select_consult_type .radio_payment .form-check-label img {
    display: block;
    background-color: #fff;
    width: 90px;
    height: 60px;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 600px) {
    .select_consult_type .radio_payment{
      width: 100% !important;
      margin-bottom: 0 !important;
    }
  }
</style>
