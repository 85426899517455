<template>
    <router-link :to="`/consult/${consult_id}/doctor/${card.id}`" class="card_doctor">
        <img :src="card.image" class="card_image" :alt="card.title" @error="setDefaultImage">
        <div class="content_doctor">
            <h4 class="name">{{card.name}}</h4>
            <!-- <p class="center" v-if="card.center != ''">{{card.center}}</p> -->
            <p class="text" v-html="card.description.substring(0, 800) + ' ...'"></p>
            <!-- <div class="rate">
                <i class="fa-solid fa-star" v-for="(rate, index) in card.rate" :key="index"></i>
                <i class="fa-regular fa-star" v-for="(rate, index) in 5-card.rate" :key="index"></i>
            </div> -->
            <!-- <p class="price">تبدأ من : <span class="number">{{card.price}}</span> {{$t("currency")}}</p> -->
        </div>
    </router-link>
</template>

<script>
export default {
    props: ["card"],
    data() {
        return {
            defaultImageUrl: localStorage.getItem('defaultImage'),
            consult_id: this.$route.params.id
        };
    },
    methods: {
        setDefaultImage(event) {
            event.target.src = this.defaultImageUrl;
        },
    },
}
</script>