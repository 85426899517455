<template>
  <section class="experiences">
    <div class="container">
        <h1 class="title" v-html="text.experiences_text"></h1>
        <!-- <p class="text">{{experience.text}}</p> -->
        <div class="experiences_list">
            <div class="list" v-for="(experience, index) in experiences" :key="index">
              <h4 class="title_list">{{experience.title}}</h4>
              <p class="text_list">{{experience.text}}</p>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { experiencesData } from "@/api/about/experiences.js";
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      status: "",
      experiences: [],
      text: {}
    };
  },
  mounted() {
    experiencesData().then((response) => {
      this.status = response.data.status;
      this.experiences = response.data.data;
      return response;
    });

    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
};
</script>