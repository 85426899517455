<template>
  <section class="header">
    <div class="container h-100">
      <swiper
          :pagination="{
            dynamicBullets: true,
            clickable: true,
          }"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper h-100"
        >
          <swiper-slide v-for="(header, index) in headers" :key="index">
            <div class="row align-items-center h-100 header_mobile_swiper">
              <div class="col-lg-6 col-md-12 col-12" data-aos="fade-left"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-once="false">
                <h1 class="title">{{header.title}}</h1>
                <h5 class="text">{{header.text}}</h5>
                <div class="buttons">
                  <PrimaryButton :PrimaryButtonLink="PrimaryButtonLink" :PrimaryButtonText="PrimaryButtonText" />
                  <SecondButton :SecondButtonLink="SecondButtonLink" :SecondButtonText="SecondButtonText" />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-12" data-aos="fade-right"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-once="false">
                <div class="image">
                  <img :src="header.image" alt="person_head" @error="setDefaultImage">
                </div>
              </div>
            </div>
          </swiper-slide>
      </swiper>
    </div>
  </section>
</template>


<script>
import { headersData } from "@/api/index/header.js";
import PrimaryButton from "@/components/include/buttons/PrimaryButton.vue"
import SecondButton from "@/components/include/buttons/SecondButton.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper';

export default {
  data() {
    return {
      PrimaryButtonLink: "consult",
      SecondButtonLink: "about",
      PrimaryButtonText: this.$t("request_consult"),
      SecondButtonText: this.$t("learn_more"),
      status: "",
      headers: [],
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    PrimaryButton,
    SecondButton,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    headersData().then((response) => {
      this.status = response.data.status;
      this.headers = response.data.data;
      // console.log(this.header)
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>