<template>
  <router-link :to="`/${SecondButtonLink}`" class="btn SecondButton">{{SecondButtonText}}</router-link>
</template>

<script>
export default {
    props: ["SecondButtonLink", "SecondButtonText"]
}
</script>

<style>

</style>