<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <div class="store_details">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-12">
                <img :src="store.image" class="image_store" :alt="store.title" @error="setDefaultImage" />
                <p class="number_page"><span>{{$t("number_of_page")}} :</span>{{store.number_of_pages}}</p>
                <!-- <p class="number_page"><span>عدد التنزيلات : </span>{{store.downloads}}</p> -->
            </div>
            <div class="col-lg-9 col-md-12 col-12">
                <h1 class="title">{{store.title}}</h1>
                <p class="category">{{store.mini_text}}</p>
                <!-- <div class="rate">
                    <i class="fa-solid fa-star" v-for="(star, index) in store.number_rate" :key="index"></i>
                    <i class="fa-regular fa-star" v-for="(star, index) in number_rate" :key="index"></i>
                    <span>({{store.average_rate}})</span>
                </div> -->
                <div class="add_to_cart">
                  <p class="price">{{store.price}} {{$t("currency")}}</p>
                  <router-link type="button" class="btn add_to_cart_btn PrimaryButton" :to="`/book/payment/${store.id}`" v-if="store.IsBuyed == false">{{$t("get_book_now")}}</router-link>
                  <router-link :to="store.pdf" type="button" class="btn add_to_cart_btn SecondButton" v-if="store.IsBuyed == true"><i class="fa-solid fa-download"></i> {{$t("download_book")}}</router-link>
                </div>
                <div class="description">
                    <h4>{{$t("about_book")}}</h4>
                    <p v-html="store.text"></p>
                </div>
                <storeRate :store="store" :rates="rates" />
            </div>
        </div>
        <div class="store">
          <storeRelated :related="related" />
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
// import { storeDetailsData } from "@/api/page/store/details.js";
import storeRate from '@/components/include/store/storeRate.vue';
import storeRelated from '@/components/include/store/storeRelated.vue';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("educational_platform"),
        subTitle: this.$t("educational_platform"),
        link: "/platform",
      },
      store: {},
      number_rate: 0,
      rates: [],
      related: [],
      defaultImageUrl: localStorage.getItem('defaultImage'),
      BookForm: {
        book_id: this.$route.params.id,
        payment_method_id: 1,
      }
    };
  },
  components: {
    Breadcrumb,
    storeRate,
    storeRelated
  },
  methods: {
    storeDetailsData() {
      let book_id = { book_id: this.$route.params.id };
      axios.post("/fetch_book_details", book_id).then(({ data }) => {
        this.status = data.status;
        this.store = data.data.details;
        this.number_rate = 5-data.data.number_rate;
        this.rates = data.data.rates;
        this.related = data.data.other_books;
        // console.log(data.data);
      });
    },
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  watch: {
    $route: {
      handler: "storeDetailsData",
      immediate: true,
    },
  },
};
</script>
<style></style>
