<template>
  <section class="cirticates">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-12">
          <h1 class="title">{{certificates.title}}</h1>
          <p class="text" v-html="certificates.text"></p>
          <!-- <ul class="certificate_list">
            <li v-for="(certificate, index) in certificates.certificate" :key="index">{{certificate}}</li>
          </ul> -->
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="certificates.image" :alt="certificates.title" @error="setDefaultImage">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { certificatesData } from "@/api/about/certificates.js";

export default {
  data() {
    return {
      status: "",
      certificates: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  mounted() {
    certificatesData().then((response) => {
      this.status = response.data.status;
      this.certificates = response.data.data;
      // console.log()
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>