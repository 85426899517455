<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="blog_details">
    <div class="container">
      <div class="row">
        <img :src="blog.image" class="header_image" :alt="blog.title" @error="setDefaultImage">
        <h1 class="title">{{blog.title}}</h1>
        <p class="text" v-html="blog.text"></p>
      </div>
    </div>
    <RelatedBlog />
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
// import { blogsDetailsData } from "@/api/page/blogs/details.js";
import RelatedBlog from '@/components/include/blogs/RelatedBlog.vue';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("blogs"),
        subTitle: "عرض الخبر",
        link: "/blogs",
      },
      blog: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    Breadcrumb,
    RelatedBlog
  },
  methods: {
    blogsDetailsData() {
      let blog_id = { blog_id: this.$route.params.id };
      axios.post("/blog_details", blog_id).then(({ data }) => {
        this.status = data.status;
        this.blog = data.data.details;
      });
    },
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
  created() {
    this.blogsDetailsData()
  },
  watch: {
    $route: {
      handler: "blogsDetailsData",
      immediate: true,
    },
  },
};
</script>

<style></style>
