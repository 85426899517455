<template>
  <!-- Modal -->
  <div
    class="modal modal_login fade"
    id="LoginModal"
    tabindex="-1"
    aria-labelledby="LoginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="title">{{$t("welcome_again")}}</h3>
          <p class="text">{{$t("please_enter_data")}}</p>
          <form @submit.prevent="login">
            <div class="form-group">
              <input
                type="email"
                name=""
                id=""
                class="form-control"
                v-model="Form.email"
                :placeholder="$t('email')"
              />
              <i class="fa-solid fa-envelope"></i>
            </div>
            <div class="form-group">
              <input
                type="password"
                name=""
                id=""
                class="form-control"
                v-model="Form.password"
                :placeholder="$t('password')"
              />
              <i class="fa-solid fa-lock"></i>
            </div>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#ForgetPasswordModal"
              class="btn forget_password_link"
            >
              {{$t("forget_password")}}
            </button>
            <button type="submit" class="btn PrimaryButton">
              {{ $t("login") }}
            </button>
            <p class="register_link">
              {{$t("dont_have_account")}}
              <button
                class="btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#RegisterModal"
              >
                {{$t("create_account")}}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      let response = await this.$store.dispatch("Login", this.Form);
      // console.log(response);
      if (this.Form.email === "" || this.Form.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 2000,
        });
      } else {
        try {
          if (response.data.status == true) {
            // console.log("message" + response.data.message);
            // console.log("status" + response.data.status);
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
              timer: 2000,
            });
            this.Form = {
              email: "",
              password: "",
            };
            location.reload()
          } else if (response.data.status == false) {
            // console.log("message : " + response.data.message);
            // console.log("status : " + response.data.status);
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
              timer: 2000,
            });
          }
        } catch (err) {
          // console.log(err.response)
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.$t("There is no user with this email"),
            timer: 2000,
          });
        }
      }
    },
  },
};
</script>

<style></style>
