<template>
    <!-- Modal -->
    <div class="modal modal_login fade" id="RegisterModal" tabindex="-1" aria-labelledby="RegisterModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="title">{{$t("welcome_to_wa3i")}}</h3>
                    <p class="text">{{$t("please_enter_data")}}</p>
                    <form @submit.prevent="register">
                        <div class="form-group">
                          <input type="text" name="" id="" v-model="Form.name" class="form-control" :placeholder="$t('full_name')">
                          <i class="fa-solid fa-user"></i>
                        </div>
                        <div class="form-group">
                          <input type="email" name="" id="" v-model="Form.email" class="form-control" :placeholder="$t('email')">
                          <i class="fa-solid fa-envelope"></i>
                        </div>
                        <div class="form-group">
                          <input type="password" name="" id="" v-model="Form.password" class="form-control" :placeholder="$t('password')">
                          <i class="fa-solid fa-lock"></i>
                        </div>
                        <button type="submit" class="btn PrimaryButton">{{$t("login")}}</button>
                        <p class="register_link"> {{$t("dont_have_account")}} <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("login")}}</button></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Form: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async register() {
      // console.log(response);
      if (
        this.Form.name === "" ||
        this.Form.email === "" ||
        this.Form.password === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 2000,
        });
      } else {
        try {
          let response = await this.$store.dispatch("Register", this.Form);
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
              timer: 2000,
            });
            this.Form = {
              email: "",
              name: "",
              password: "",
            };
            location.reload();
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
              timer: 2000,
            });
          }
        } catch (err) {
          // console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
            timer: 2000,
          });
        }
      }
    },
  },
};
</script>