<template>
  <HeaderCenter />
  <CenterConsult />
  <Doctor />
  <Blogs />
</template>

<script>
import HeaderCenter from "@/components/include/Center/HeaderCenter.vue";
import Doctor from "@/components/include/HomePage/doctor.vue";
import CenterConsult from "@/components/include/HomePage/CenterConsult.vue";
import Blogs from "@/components/include/HomePage/blogs.vue";

export default {
  components: {
    HeaderCenter,
    CenterConsult,
    Doctor,
    Blogs,
  },
};
</script>

<style scoped>
.center_consult {
  background-color: #fff;
}
</style>
