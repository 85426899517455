<template>
    <!-- Modal -->
    <div class="modal modal_login fade" id="ForgetPasswordModal" tabindex="-1" aria-labelledby="ForgetPasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="title">{{$t("forget_password")}}</h3>
                    <p class="text">{{$t("please_enter_email")}}</p>
                    <form>
                        <div class="form-group">
                          <input type="email" name="" id="" class="form-control" :placeholder="$t('email')">
                          <i class="fa-solid fa-envelope"></i>
                        </div>
                        <button type="submit" class="btn PrimaryButton">{{$t("send")}}</button>
                        <p class="register_link">{{$t("remember_password")}}<button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("return_login")}}</button></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style>

</style>