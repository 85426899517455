<template>
  <div class="doctor_details_header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="doctor.image" alt="doctor" @error="setDefaultImage" />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <h1 class="title">{{doctor.name}}</h1>
          <p class="center">{{doctor.mini_description}}</p>
          <p class="text">{{doctor.description}}</p>
          <!-- <PrimaryButton
            :PrimaryButtonLink="PrimaryButtonLink"
            :PrimaryButtonText="PrimaryButtonText"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/include/buttons/PrimaryButton.vue";

export default {
  props: ["doctor"],
  data() {
    return {
      PrimaryButtonLink: "test",
      PrimaryButtonText: this.$t("consultation_reservation"),
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  components: {
    PrimaryButton,
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>

<style></style>
