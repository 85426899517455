<template>
  <section class="header_center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <h1 class="title">{{center.title}}</h1>
          <p class="text">{{center.text}}</p>
          <PrimaryButton
            :PrimaryButtonLink="PrimaryButtonLink"
            :PrimaryButtonText="PrimaryButtonText"
          />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="center.image" :alt="center.title" @error="setDefaultImage" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import PrimaryButton from '@/components/include/buttons/PrimaryButton.vue';

export default {
  data() {
    return {
      PrimaryButtonLink: "consult",
      PrimaryButtonText: this.$t("consultation_reservation"),
      defaultImageUrl: localStorage.getItem('defaultImage'),
      center: {}
    };
  },
  components: {
    PrimaryButton,
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
    fetchStoreHeader() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_store_header").then(({ data }) => {
          this.status = data.status;
          this.center = data.data;
          // console.log(this.payments)
      });
    },
  },
  created() {
    this.fetchStoreHeader();
  }
};
</script>

<style></style>
