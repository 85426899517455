<template>
  <div class="row">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h4>{{$t("products_like")}}</h4>
        <router-link to="/stores/0" class="text-secondary">{{$t("show_all")}}</router-link>
    </div>
    <div class="col-lg-6 col-md-6 col-12" v-for="(store, index) in related" :key="index" >
        <storeCard :store="store" />
    </div>
  </div>
</template>

<script>
import storeCard from './storeCard.vue'

export default {
    props: ["related"],
    components: {
        storeCard
    }
}
</script>

<style>
    h4 {
        font-family: "semibold";
        color: #11191B;
        margin-bottom: 2rem;
    }
    a {
        font-family: "regular";
        color: #818889;
    }
</style>