<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="podcasts">
    <div class="container">
      <h4 class="title_section">{{$t("most_listening")}}</h4>
      <mostListening />

      <h4 class="title_section">{{$t("all_podcasts")}}</h4>
      <podcastCard v-for="(podcast, index) in podcasts" :key="index" :podcast="podcast" />
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import { podcastsData } from "@/api/podcasts/podcasts.js";
import podcastCard from '@/components/include/podcast/podcast_card.vue';
import mostListening from '@/components/include/podcast/most_listening.vue';

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("podcast"),
        subTitle: "",
        link: "",
      },
      podcasts: [],
    };
  },
  components: {
    Breadcrumb,
    mostListening,
    podcastCard
  },
  created() {
    podcastsData().then((response) => {
      this.status = response.data.status;
      this.podcasts = response.data.data.podcasts;
      // console.log(this.podcasts)
      return response;
    });
  },
};
</script>