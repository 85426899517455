<template>
  <section class="store">
    <div class="container">
      <div class="d-flex justify-content-between align-items-start mb-3">
        <div class="content">
          <h4 class="head_section">{{$t("store")}}</h4>
          <!-- <h1 class="title_section" v-html="text.store_text"></h1> -->
          <h5 class="text_section" v-html="text.store_text"></h5>
        </div>
        <SecondButton :SecondButtonLink="SecondButtonLink" :SecondButtonText="SecondButtonText" />
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12" v-for="(store, index) in stores" :key="index"
        data-aos="zoom-in-up"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false">
          <StoreCard :store="store" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeData } from "@/api/index/store.js";
import StoreCard from "@/components/include/store/storeCard.vue";
import SecondButton from "@/components/include/buttons/SecondButton.vue";
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      SecondButtonLink: "stores/1",
      SecondButtonText: this.$t("show_more"),
      status: "",
      stores: [],
      text: {}
    };
  },
  components: {
    StoreCard,
    SecondButton
  },
  mounted() {
    storeData().then((response) => {
      this.status = response.data.status;
      this.stores = response.data.data;
      // console.log(this.store)
      return response;
    });

    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
};
</script>