<template>
  <section class="header_about">
    <div class="container">
      <h1 class="title">{{about.title}}</h1>
      <h5 class="text" v-html="about.text"></h5>
      <img :src="about.image" :alt="about.title" @error="setDefaultImage">
    </div>
  </section>
</template>

<script>
import { headersAboutData } from "@/api/about/header.js";

export default {
  data() {
    return {
      status: "",
      about: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  mounted() {
    headersAboutData().then((response) => {
      this.status = response.data.status;
      this.about = response.data.data;
      // console.log(this.about)
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>