<template>
  <section class="breadcrumb">
    <div class="container">
        <h4 class="title">{{breadcrumb.title}}</h4>
        <div class="routes">
          <router-link to="/">{{ $t("home") }} &nbsp; > &nbsp; </router-link>

          <p v-if="breadcrumb.link == ''">{{ breadcrumb.title }}</p>
          <router-link v-else :to="breadcrumb.link">{{ breadcrumb.title }}</router-link>

          <p v-if="breadcrumb.subTitle != ''"> &nbsp;	> &nbsp; {{ breadcrumb.subTitle }}</p>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    props: ["breadcrumb"],
    data() {
      return {

      }
    }
}
</script>

<style>

</style>