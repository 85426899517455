<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <form class="setting" v-on:submit.prevent="SubmitForm">
    <h4 class="title">{{ $t("general_setting") }}</h4>
    <div class="row">
      <div class="col-xl-2 col-lg-4 col-md-4 col-12">
        <div class="view_image mb-4">
          <!-- <label for="files">+ اضف صورة</label> -->
          <div class="position-relative">
            <!-- <i class="fa-solid fa-camera"></i> -->
            <div class="image-preview">
              <!-- v-if="AccountDataForm.image != ''" -->
              <img
                class="preview"
                v-if="UpdateProfileForm.image"
                :src="UpdateProfileForm.image"
              />
              <img class="preview" v-else src="@/assets/media/profile.png" />
            </div>
            <div class="file-upload-form">
              <label for="imageProfile">{{ $t("add_new_image") }}</label>
              <input
                type="file"
                id="imageProfile"
                hidden
                @change="previewImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-8 col-12">
        <p>{{ $t("basic_information") }}</p>
        <div class="form-group">
          <i class="fa-solid fa-user"></i>
          <input
            type="text"
            name=""
            id=""
            class="form-control data_input"
            v-model="UpdateProfileForm.name"
            :placeholder="$t('full_name')"
          />
        </div>
        <div class="form-group">
          <i class="fa-solid fa-mobile-screen-button"></i>
          <input
            type="number"
            name=""
            id=""
            class="form-control data_input"
            v-model="UpdateProfileForm.phone"
            :placeholder="$t('phone')"
          />
        </div>
        <div class="form-group">
          <i class="fa-solid fa-envelope"></i>
          <input
            type="email"
            name=""
            id=""
            class="form-control data_input"
            v-model="UpdateProfileForm.email"
            :placeholder="$t('email')"
          />
        </div>
        <p class="mt-5">{{ $t("additional_data") }}</p>
        <div class="form-group">
          <select
            name=""
            id=""
            class="form-control"
            v-model="UpdateProfileForm.gender"
          >
            <option value="" hidden selected disabled>
              {{ $t("gender") }}
            </option>
            <option value="1">{{ $t("male") }}</option>
            <option value="2">{{ $t("female") }}</option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="number"
            name=""
            id=""
            class="form-control"
            v-model="UpdateProfileForm.age"
            :placeholder="$t('age')"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            name=""
            id=""
            class="form-control"
            v-model="UpdateProfileForm.chronic_diseases"
            :placeholder="$t('description_sick')"
          />
        </div>
        <button type="submit" class="btn submit_btn">{{ $t("save") }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { profileData } from "@/api/profile/profile.js";
import Loading from "@/components/include/LottieFiles/loading.vue"

export default {
  data() {
    return {
      UpdateProfileForm: {
        image: "",
        name: "",
        email: "",
        phone: "",
        gender: "",
        age: "",
        chronic_diseases: "",
      },
      loading: false,
    };
  },
  components: {
    Loading
  },
  methods: {
    // image
    previewImage(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.UpdateProfileForm.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    // image

    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/update_profile", this.UpdateProfileForm)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            location.reload();
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
  mounted() {
    profileData().then((response) => {
      this.status = response.data.status;
      this.UpdateProfileForm = response.data.data;
      // console.log(this.UpdateProfileForm)
      return response;
    });
  },
};
</script>

<style></style>
