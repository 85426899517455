<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <section class="doctors">
        <div class="container">
            <div class="row">
                <div class="col-12" v-for="(card, index) in doctors" :key="index">
                    <doctorConsultCard :card="card" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import { consultDoctorData } from "@/api/page/consult/consultDoctor.js";
import CardDoctor from '@/components/include/doctor/CardDoctor.vue';
import doctorConsultCard from '@/components/include/Consult/doctorConsultCard.vue';

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("consulting"),
                subTitle: this.$t("choose_doctor"),
                link: "/platform"
            },
            doctors: []
        }
    },
    components: {
        Breadcrumb,
        doctorConsultCard
    },
    mounted() {
        let consult_id = { consultation_id: this.$route.params.id };
        consultDoctorData(consult_id).then((response) => {
            this.status = response.data.status;
            this.doctors = response.data.data;
            // console.log(this.platforms)
            return response;
        });
    },
}
</script>

<style scoped>
    .doctors {
        background-image: unset;
        background-color: #fff;
    }
    .card_doctor {
        border-bottom: 1px solid #818889;
        border-radius: 0 !important;
    }
</style>