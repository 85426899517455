<template>
  <router-link :to="`/blog/${blog.id}`" class="blog_card">
    <img :src="blog.image" :alt="blog.title" @error="setDefaultImage">
    <span class="date">{{blog.date}}</span>
    <span class="read_number">{{blog.read_number}} {{$t("number_of_read")}}</span>
    <h4 class="title">{{blog.title}}</h4>
  </router-link>
</template>

<script>
export default {
  props: ["blog"],
  data() {
    return {
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
}
</script>

<style>

</style>