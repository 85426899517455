<template>
  <form class="setting" v-on:submit.prevent="SubmitForm">
    <h4 class="title">{{ $t("change_password") }}</h4>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-8 col-12">
        <div class="form-group">
          <i class="fa-solid fa-lock"></i>
          <input
            type="password"
            name=""
            id=""
            class="form-control data_input"
            v-model="UpdatePasswordForm.old_password"
            :placeholder="$t('old_password')"
          />
        </div>
        <div class="form-group">
          <i class="fa-solid fa-lock"></i>
          <input
            type="password"
            name=""
            id=""
            class="form-control data_input"
            v-model="UpdatePasswordForm.new_password"
            :placeholder="$t('new_password')"
          />
        </div>
        <button type="submit" class="btn submit_btn">{{ $t("save") }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      UpdatePasswordForm: {
        old_password: "",
        new_password: ""
      },
    };
  },
  methods: {
    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/change_password", this.UpdatePasswordForm)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.UpdatePasswordForm = {
              old_password: "",
              new_password: ""
            }
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  }
};
</script>

<style></style>
