<template>
    <Breadcrumb :breadcrumb="breadcrumb" />
    <section class="doctors">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12" v-for="(card, index) in doctors.data" :key="index">
                    <CardDoctor :card="card" />
                </div>
            </div>
            <div class="row">
            <pagination
                :data="doctors"
                class="mx-auto d-flex align-items-center justify-content-center pagination"
                @pagination-change-page="doctorsData"
            >
                <span slot="prev-nav">&lt;</span>
                <span slot="next-nav">&gt;</span>
            </pagination>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Breadcrumb from '@/components/include/breadcrumb/breadcrumb.vue';
import CardDoctor from '@/components/include/doctor/CardDoctor.vue';
// import { blogsData } from "@/api/page/blogs/blogs.js";

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t("doctors"),
                subTitle: "",
                link: ""
            },
            doctors: [],
        }
    },
    components: {
        Breadcrumb,
        pagination,
        CardDoctor
    },
    methods: {
        doctorsData(page = 1) {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.get("/fetch_doctors?page=" + page).then(({ data }) => {
            this.status = data.status;
            this.doctors = data.data;
            // console.log(this.doctors)
        });
        },
    },
    created() {
        this.doctorsData();
    },
}
</script>

<style scoped>
    .doctors {
        background-image: unset;
    }
</style>