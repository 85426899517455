<template>
  <section class="features">
    <div class="container">
      <h4 class="head_section">{{ $t("available_features") }}</h4>
      <!-- <h1 class="title_section" v-html="text.features_text"></h1> -->
      <h5 class="text_section" v-html="text.features_text"></h5>
      <div class="feature_list">
        <div
          class="feature"
          v-for="(feature, index) in features"
          :key="index"
           data-aos="fade-down"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
        >
          <img
            :src="feature.image"
            :alt="feature.title"
            @error="setDefaultImage"
          />
          <h4 class="title">{{ feature.title }}</h4>
          <p class="text">{{ feature.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { featureData } from "@/api/index/feature.js";
import { websiteTextsData } from "@/api/index/websiteTexts.js";

export default {
  data() {
    return {
      status: "",
      features: [],
      text: {},
      defaultImageUrl: localStorage.getItem('defaultImage'),
    };
  },
  mounted() {
    featureData().then((response) => {
      this.status = response.data.status;
      this.features = response.data.data;
      // console.log(this.feature)
      return response;
    });

    websiteTextsData().then((response) => {
      this.status = response.data.status;
      this.text = response.data.data;
      // console.log(this.why)
      return response;
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.defaultImageUrl;
    },
  },
};
</script>
