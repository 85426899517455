<template>
  <router-link :to="`/podcast/${podcast.id}`" class="card_podcast_lite" v-for="(podcast, index) in podcast.podcasts" :key="index"
  data-aos="fade-down-left"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false" >
        <img :src="podcast.image" class="podcast_image" alt="podcast" @error="setDefaultImage">
        <div class="content">
            <span class="title">{{podcast.title}}</span>
            <span class="date">{{podcast.date}}</span>
        </div>
        <i class="fa-solid fa-circle-play"></i>
    </router-link>
</template>

<script>
export default {
    props: ["podcast"],
    data() {
        return {
        defaultImageUrl: localStorage.getItem('defaultImage'),
        };
    },
    methods: {
        setDefaultImage(event) {
        event.target.src = this.defaultImageUrl;
        },
    },
}
</script>

<style>

</style>