<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="store">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="category_store">
            <li>
              <router-link :to="`/stores/0`">{{$t("all")}}</router-link>
            </li>
            <Category
              v-for="(category, index) in categories"
              :index="index"
              :category="category"
              :key="index"
            />
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12" v-for="(store, index) in stores" :key="index">
          <storeCard :store="store" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import Category from "@/components/include/store/Category.vue";
import storeCard from "@/components/include/store/storeCard.vue";
import { storeCategoryData } from "@/api/page/store/category.js";
// import { storeData } from "@/api/page/store/store.js";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("store"),
        subTitle: "",
        link: "",
      },
      categories: [],
      stores: [],
    };
  },
  components: {
    Breadcrumb,
    Category,
    storeCard
  },
  methods: {
    storeData() {
      let category_id = { category_id: this.$route.params.id };
      axios.post("/fetch_books_by_category_id", category_id).then(({ data }) => {
        this.status = data.status;
        this.stores = data.data;
        // console.log(this.questionnaires);
      });
    },
  },
  created() {
    storeCategoryData().then((response) => {
      this.status = response.data.status;
      this.categories = response.data.data;
      //   console.log(this.categories)
      return response;
    });
  },
  watch: {
    $route: {
      handler: "storeData",
      immediate: true,
    },
  },
};
</script>

<style>
.store_card {
  margin-bottom: 2rem;
  width: 100% !important;
}
.store_card .title {
  width: 90%;
}
</style>
