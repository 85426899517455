<template>
  <Breadcrumb :breadcrumb="breadcrumb" />
  <section class="reservations">
    <div class="container">
      <h4 class="title mb-5">{{ $t("reservations") }}</h4>
      <reservationCard
        :reservation="reservation"
        v-for="(reservation, index) in reservations.data"
        :key="index"
      />
    </div>
    <div class="row">
      <pagination
          :data="reservations"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchReservations"
      >
          <span slot="prev-nav">&lt;</span>
          <span slot="next-nav">&gt;</span>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Breadcrumb from "@/components/include/breadcrumb/breadcrumb.vue";
import reservationCard from "@/components/include/Reservations/reservationCard.vue";

export default {
  data() {
    return {
      breadcrumb: {
        title: this.$t("reservations"),
        subTitle: "",
        link: "",
      },
      reservations: [],
    };
  },
  methods: {
    fetchReservations(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_your_reservations?page=" + page).then(({ data }) => {
          this.status = data.status;
          this.reservations = data.data;
          // console.log(this.reservations)
          // console.log(this.libraries);
      });
    },
  },
  created() {
    this.fetchReservations()
  },
  components: {
    Breadcrumb,
    reservationCard,
    pagination
  },
};
</script>

<style></style>
