<template>
    <!-- Button trigger modal -->
    <button type="button" class="btn login_btn" data-bs-toggle="modal" data-bs-target="#LoginModal">{{$t("login")}}</button>
    <LoginModal />
    <RegisterModal />
    <ForgetPasswordModal />
</template>

<script>
import LoginModal from "@/components/include/Modals/auth/LoginModal.vue"
import RegisterModal from '../Modals/auth/RegisterModal.vue'
import ForgetPasswordModal from '../Modals/auth/ForgetPasswordModal.vue';

export default {
    components: {
        LoginModal,
        RegisterModal,
        ForgetPasswordModal
    }
}
</script>

<style>

</style>